import { z } from 'zod';
import { WelcomeEmailSchema } from '../scim-schemas/scim_schema__welcome-email';
import { GroupAEBCustomsSchema } from '../scim-schemas/scim_schema__group-aeb-customs';
import { SCIMSchemaId } from '../scim-schemas/scim-schema-id';
import {
  messageCoreSCIMSchema,
  messageGroupAEBCustomsSchema,
  messageWelcomeEmailSchema,
  validateCoreSCIMSchema,
  validateGroupAEBCustomsSchema,
  validateWelcomeEmailSchema,
} from './user.schema.refinements';
import { CoreUserSchema } from '../scim-schemas/scim_schema__core_user';

export const UserSchema = CoreUserSchema.extend({
  schemas: z.array(SCIMSchemaId),
  'urn:ietf:params:scim:schemas:extension:welcome_email:2.0:User':
    WelcomeEmailSchema.optional(),
  'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:User':
    GroupAEBCustomsSchema.optional(),
});

// TODO this refine function should be chain to the schema definition
UserSchema.refine(validateCoreSCIMSchema, messageCoreSCIMSchema)
  .refine(validateWelcomeEmailSchema, messageWelcomeEmailSchema)
  .refine(validateGroupAEBCustomsSchema, messageGroupAEBCustomsSchema);

export type User = z.infer<typeof UserSchema>;
