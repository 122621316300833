import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input() dropdownItems:any = [];
  @Output() selectedOption: any = new EventEmitter<string>();

  selectedRoles: string[] = [];
  expanded = false;
  allCheckboxesSelected = false;

  onSelect(event: any) {
    const role = event.target.id;
    if (event.target.checked) {
      this.selectedRoles.push(role);
    } else {
      this.selectedRoles = this.selectedRoles.filter((r) => r !== role);
    }
    this.selectedOption.emit(this.selectedRoles);
  }

  @ViewChild('multiselect') multiselect: ElementRef;

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    const clickedMultiselectDiv = this.multiselect.nativeElement.contains(target);
    if (!clickedInside && !clickedMultiselectDiv) {
      this.closeDropdown();
    }
  }

 showCheckboxes() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  if (!this.expanded) {
    checkboxes.style.display = "block";
    this.expanded = true;
  } else {
    checkboxes.style.display = "none";
    this.expanded = false;
    }
  }

closeDropdown() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  checkboxes.style.display = "none";
  this.expanded = false;
}

CheckIfAllAreSelected() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  const inputs = checkboxes.querySelectorAll("input");
  let validation = [];
  for (let i= 0; i< inputs.length; i++) {
    validation.push(inputs[i].checked);
    }
  if(validation.every(Boolean)) {
    this.allCheckboxesSelected = true;
  } else {
    this.allCheckboxesSelected = false;
  }
}

selectAllToggle() {
  this.allCheckboxesSelected = !this.allCheckboxesSelected;
  if (this.allCheckboxesSelected === true) {
    const checkboxes = document.getElementById("checkboxes") as HTMLElement;
    const inputs = checkboxes.querySelectorAll("input");
    inputs.forEach((input) => {
      input.checked = true;
    });
    this.selectedRoles = this.dropdownItems;
    } else {
      const checkboxes = document.getElementById("checkboxes") as HTMLElement;
      const inputs = checkboxes.querySelectorAll("input");
      inputs.forEach((input) => {
        input.checked = false;
      });
      this.selectedRoles = [];
      }
    this.selectedOption.emit(this.selectedRoles);
  }

  selectAll() {
    this.allCheckboxesSelected = true;
    this.selectAllToggle();
  }

}
