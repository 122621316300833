import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { BehaviorSubject, filter, map, pairwise, startWith } from 'rxjs';
import { deepEqual } from '@shared/xbp-commons';
import { isNullOrUndefined } from '@xbp-commons/types';
import { merge } from 'lodash-es';

// Utility type to infer the raw values of the controls
type RawValue<TControl extends Record<string, AbstractControl>> =
  TControl extends { [key: string]: AbstractControl<any, any> }
    ? {
        [K in keyof TControl]: TControl[K] extends AbstractControl<any, infer V>
          ? V
          : any;
      }
    : never;

type FormError = { errorKey: string; errorMessage: string };

export class XbpForm<
  TControl extends { [key: string]: AbstractControl<unknown, unknown> },
> extends FormGroup<TControl> {
  private _initRawValues: RawValue<TControl>;
  private _prevRawValues: RawValue<TControl>;
  private _currRawValues$: BehaviorSubject<RawValue<TControl>>;
  private _hasChanged$ = new BehaviorSubject(false);

  private _errorsAsArray$ = new BehaviorSubject<Array<FormError>>([]);

  constructor(
    controls: TControl,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);

    // Explicitly cast to match RawValue<TControl>
    this._initRawValues = this.getRawValue() as RawValue<TControl>;
    this._prevRawValues = this.getRawValue() as RawValue<TControl>;
    this._currRawValues$ = new BehaviorSubject(
      this.getRawValue() as RawValue<TControl>,
    );

    this.trackValueChanges();
    this.trackStatusChanges();
  }

  public static from<
    TControl extends { [key: string]: AbstractControl<unknown, unknown> },
  >(formGroup: FormGroup<TControl>): XbpForm<TControl> {
    const controls = formGroup.controls as TControl;

    const trackedForm = new XbpForm<TControl>(
      controls,
      formGroup.validator,
      formGroup.asyncValidator,
    );

    trackedForm._initRawValues = formGroup.getRawValue() as RawValue<TControl>;
    trackedForm._prevRawValues = formGroup.getRawValue() as RawValue<TControl>;
    trackedForm._currRawValues$ = new BehaviorSubject(
      formGroup.getRawValue() as RawValue<TControl>,
    );

    return trackedForm;
  }

  public get initRawValues(): RawValue<TControl> {
    return this._initRawValues;
  }

  public get prevRawValues(): RawValue<TControl> {
    return this._prevRawValues;
  }

  public get currRawValues(): RawValue<TControl> {
    return this._currRawValues$.value;
  }

  public get hasChanged(): boolean {
    return this._hasChanged$.value;
  }

  public get hasChanged$() {
    return this._hasChanged$.asObservable();
  }

  public get valueChanged$() {
    return this._hasChanged$.pipe(map(() => this.currRawValues));
  }

  public get onChanged$() {
    return this._hasChanged$.pipe(
      startWith(false),
      pairwise(),
      filter(([prev, curr]) => !prev && curr),
      map(([prev, curr]) => curr),
    );
  }

  public get errorsAsArray$() {
    return this._errorsAsArray$.asObservable();
  }

  public get errorsAsArray() {
    return this._errorsAsArray$.value;
  }

  public markAsHasChanged(): void {
    this._hasChanged$.next(true);
    this.markAsTouched();
    this.markAsDirty();
  }

  public setValuesAndResetTracking(values: RawValue<TControl>): void {
    this._initRawValues = values;
    this._prevRawValues = values;
    this._currRawValues$.next(values);
    this.setValue(values);
  }

  public resetTracking(): void {
    this._initRawValues = this._currRawValues$.value;
    this._prevRawValues = this._currRawValues$.value;
    this._currRawValues$.next(this._currRawValues$.value);
    this.reset(this._currRawValues$.value);
  }

  public patchValuesAndResetTracking(
    values: Partial<RawValue<TControl>>,
  ): void {
    this._currRawValues$.next(merge(this._currRawValues$.value, values));
    this._initRawValues = { ...this._currRawValues$.value };
    this._prevRawValues = { ...this._currRawValues$.value };
    this.reset(this._currRawValues$.value);
  }

  public resetToInitialValues(): void {
    this._prevRawValues = this._initRawValues;
    this.reset(this._initRawValues);
  }

  private trackValueChanges(): void {
    this.valueChanges
      .pipe(
        startWith(this.initRawValues),
        map(() => this.getRawValue() as RawValue<TControl>),
        pairwise(),
      )
      .subscribe(([prev, curr]) => {
        this._prevRawValues = prev;
        this._currRawValues$.next(curr);

        this._hasChanged$.next(
          !deepEqual(this._initRawValues, this._currRawValues$.value),
        );

        this.updateErrorsAsArray();
      });
  }

  private trackStatusChanges(): void {
    this.statusChanges.subscribe(() => {
      this.updateErrorsAsArray();
    });
  }

  private updateErrorsAsArray(): void {
    const errors: Array<FormError> = [];
    if (isNullOrUndefined(this.errors)) {
      this._errorsAsArray$.next([]);
      return;
    }

    Object.keys(this.errors).forEach((errorKey) => {
      if (isNullOrUndefined(this.errors)) return;
      if (isNullOrUndefined(this.errors[errorKey])) return;
      errors.push({ errorKey, errorMessage: this.errors[errorKey] });
    });

    this._errorsAsArray$.next(errors);
  }
}
