import { Component, Inject } from '@angular/core';
import {
  MODAL_DATA,
  ModalContainerComponent,
} from '@shared/services/modal/modal-container/modal-container.component';
import {
  ButtonComponent,
  ComboboxComponent,
  ComboboxOptionComponent,
  FieldErrorComponent,
  GLSTemplateDirective,
  ModalComponent,
  ToggleSwitchMultipleComponent,
  ToggleSwitchMultipleOptionComponent,
} from '@gls/platform-ui';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiProductForm } from '@models/forms/api-product-form.schema';
import { isFunction } from '@xbp-commons/types';
import { forbiddenValuesValidator } from '@xbp-commons/forms';
import { isArray } from '@shared/xbp-commons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-add-api-product',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    GLSTemplateDirective,
    ButtonComponent,
    ComboboxComponent,
    ComboboxOptionComponent,
    FormsModule,
    ReactiveFormsModule,
    ToggleSwitchMultipleComponent,
    ToggleSwitchMultipleOptionComponent,
    FieldErrorComponent,
  ],
  templateUrl: './modal-add-api-product.component.html',
  styleUrl: './modal-add-api-product.component.scss',
})
export class ModalAddApiProductComponent {
  protected apiProductFormGroup: ApiProductForm = new FormGroup({
    productName: new FormControl<string>('customs-consignments-v3', [
      Validators.required,
    ]),
    approvalStatus: new FormControl<string>('approve', [Validators.required]),
  });

  constructor(
    private _modalContainer: ModalContainerComponent<ModalAddApiProductComponent>,
    @Inject(MODAL_DATA)
    public data: { existingApiProducts: Array<string>; onSubmit: Function },
  ) {
    if (isArray(this.data.existingApiProducts)) {
      this.apiProductFormGroup.controls.productName.addValidators(
        forbiddenValuesValidator(this.data.existingApiProducts),
      );
    }
  }

  public submit(): void {
    const onSubmit = this.data.onSubmit;
    this.apiProductFormGroup.markAllAsTouched();

    if (!isFunction(onSubmit)) return;
    if (this.apiProductFormGroup.invalid) return;

    onSubmit(this.apiProductFormGroup.value);
    this.close();
  }

  public close(): void {
    this._modalContainer.destroy();
  }
}
