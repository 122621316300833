import { ZodSchema } from 'zod';
import { FormGroup } from '@angular/forms';
import { DataTransformer } from '@shared/xbp-commons';
import { FormType } from '@xbp-commons/utilities-schemas-and-forms/data-transformer/data-transformer';

export class SchemaToFormDataTransformer<
  TRawForm,
  TForm extends FormType<TRawForm>,
  TData,
> extends DataTransformer<TRawForm, TForm, TData> {
  constructor(
    formSchema: ZodSchema<TRawForm>,
    dataSchema: ZodSchema<TData>,
    transformToForm: (data: TData, form: FormGroup<TForm>) => void,
  ) {
    super(formSchema, dataSchema, transformToForm, undefined as never);
  }

  public override toData(): TData {
    throw new Error(
      'SchemaToFormDataTransformer does not support form-to-data transformation.',
    );
  }
}
