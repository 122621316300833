import { ZodSchema } from 'zod';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type FormType<T> = {
  [K in keyof T]: T[K] extends Array<string>
    ? FormControl<T[K]> // Adjust array fields to use FormControl<string[]>
    : T[K] extends Array<infer U>
      ? FormArray<FormGroup<FormType<U>>> // Keep current structure for non-primitive arrays
      : T[K] extends object
        ? FormGroup<FormType<T[K]>> // Keep nested objects as FormGroup
        : FormControl<T[K]>;
};

export function buildFormType<T>(formGroup: FormGroup): FormGroup<FormType<T>> {
  return formGroup as FormGroup<FormType<T>>;
}

export class DataTransformer<
  TRawForm, // The raw data structure (field values)
  TForm extends FormType<TRawForm>, // The form structure derived from raw data
  TData, // Backend or normalized data structure
  Foo = any,
> {
  constructor(
    private formSchema: ZodSchema<TRawForm>, // Zod schema for raw form data
    private dataSchema: ZodSchema<TData>, // Zod schema for backend data
    private transformToForm: (
      data: TData,
      form: FormGroup<TForm>,
      callback: ((foo: Foo) => void) | null,
    ) => void, // Form mutation logic
    private transformToData: (form: FormGroup<TForm>) => TData, // Form extraction logic
  ) {}

  public toForm(
    data: TData,
    form: FormGroup<TForm>,
    callback: ((foo: Foo) => void) | null = null,
  ): void {
    // Parse and validate the backend data
    const parsedData = this.dataSchema.parse(data);

    // Mutate the provided form
    this.transformToForm(parsedData, form, callback);
  }

  public toData(form: FormGroup<TForm>): TData {
    // Extract raw form values, ensure they match `TRawForm`, and validate them
    const rawValues = form.getRawValue(); // Type-safe due to `FormType`
    const parsedValues = this.formSchema.parse(rawValues);

    // Return the backend representation
    return this.transformToData(form);
  }

  public transform(
    form: FormGroup<TForm>,
    direction: '<-' | '->',
    data: TData,
  ) {
    if (direction === '<-') return this.toForm(data, form);
    else return this.toData(form);
  }
}
