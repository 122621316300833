<div>
  <div formArrayName="credentials" class="credentials-section">
    <div class="heading">
      <div class="text-subheading">Credentials</div>
      <xbp-horizontal-line />
    </div>
    <div class="credential-list">
      @for (
        credentialFormGroup of credentialsFormArray.controls;
        track credentialFormGroup.value.clientId;
        let credentialFormGroupIndex = $index;
        let lastCredential = $last
      ) {
        <div class="credential-item">
          <ng-container [formGroupName]="credentialFormGroupIndex">
            <div class="credential-information">
              <div class="left">
                <span class="text-body-strong">◼ </span>
                <span class="text-body-strong">
                  {{ credentialFormGroup.value.clientId }}
                </span>
                <span class="text-caption"> (Credential ID)</span>
              </div>
              <div class="right">
                <gls-button
                  (click)="addApiProduct(credentialFormGroup)"
                  size="small"
                  variant="secondary"
                >
                  Add API Product
                </gls-button>
              </div>
            </div>
            <app-form-api-products
              [credentialFormGroupIndex]="credentialFormGroupIndex"
            />
            <div class="credential-meta">
              <div class="heading">
                <div class="text-caption">Meta</div>
                <xbp-horizontal-line />
              </div>
              <div class="information text-body">
                <div
                  class="left"
                  [glsTooltip]="
                    credentialFormGroup.value.issuedAt | date: 'full'
                  "
                  [placement]="'top-start'"
                >
                  • Issued
                  {{ credentialFormGroup.value.issuedAt | relativeTime }}
                </div>
                <div class="right">
                  @if (credentialFormGroup.value.expireAt === -1) {
                    <span>• Unexpired</span>
                  } @else {
                    <span
                      [glsTooltip]="
                        credentialFormGroup.value.expireAt | date: 'full'
                      "
                    >
                      • Expired
                      {{ credentialFormGroup.value.expireAt | relativeTime }}
                    </span>
                  }
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      } @empty {
        <div class="text-caption">No Credentials</div>
      }
    </div>
  </div>
</div>
