import { filter, map, OperatorFunction } from 'rxjs';
import { DataState } from '@xbp-commons/async-data/data-state.type';

export function filterSuccessWithData<T>(): OperatorFunction<
  [DataState, T | null],
  T
> {
  return (source$) =>
    source$.pipe(
      // Avoid destructuring here to sidestep TS1230
      filter((value): value is [DataState, NonNullable<T>] => {
        const [state, data] = value;
        return state === 'success-with-data' && data !== null;
      }),
      // Map to extract the 'data' part of the tuple
      map(([, data]) => data as NonNullable<T>),
    );
}
