import { z } from 'zod';
import { AppCredentialSchema } from '../others/app-credential.schema';

export const CoreAppSchema = z.object({
  id: z.string().uuid(),
  externalId: z.string().uuid(),
  name: z.string(),
  callbackUrl: z.string(),
  displayName: z.string(),
  approvalStatus: z.union([
    z.literal('approved'),
    z.literal('revoked'),
    z.literal('pending'),
  ]),
  credentials: z.array(AppCredentialSchema),
});
