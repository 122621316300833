import { ZodSchema } from 'zod';
import { FormGroup } from '@angular/forms';
import { DataTransformer } from '@shared/xbp-commons';
import { FormType } from '@xbp-commons/utilities-schemas-and-forms/data-transformer/data-transformer'; // FormToSchemaDataTransformer: Converts form data into schema-compliant backend data

export class FormToSchemaDataTransformer<
  TRawForm,
  TForm extends FormType<TRawForm>,
  TData,
> extends DataTransformer<TRawForm, TForm, TData> {
  constructor(
    formSchema: ZodSchema<TRawForm>,
    dataSchema: ZodSchema<TData>,
    transformToData: (form: FormGroup<TForm>) => TData,
  ) {
    super(formSchema, dataSchema, undefined as never, transformToData);
  }

  public override toForm(): void {
    throw new Error(
      'FormToSchemaDataTransformer does not support data-to-form transformation.',
    );
  }
}
