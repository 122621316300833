<svg
  width="420"
  height="257"
  viewBox="0 0 420 257"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2678_25730)">
    <path
      d="M182.161 257C234.717 257 277.322 249.485 277.322 240.216C277.322 230.946 234.717 223.431 182.161 223.431C129.605 223.431 87 230.946 87 240.216C87 249.485 129.605 257 182.161 257Z"
      fill="#3F3D56"
    />
    <path
      d="M239.998 185.705C291.361 185.705 333 144.134 333 92.8526C333 41.5715 291.361 0 239.998 0C188.634 0 146.996 41.5715 146.996 92.8526C146.996 144.134 188.634 185.705 239.998 185.705Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.05"
      d="M239.998 169.505C282.4 169.505 316.774 135.187 316.774 92.8526C316.774 50.5184 282.4 16.1998 239.998 16.1998C197.595 16.1998 163.222 50.5184 163.222 92.8526C163.222 135.187 197.595 169.505 239.998 169.505Z"
      fill="black"
    />
    <path
      opacity="0.05"
      d="M239.998 155.676C274.75 155.676 302.923 127.549 302.923 92.8526C302.923 58.156 274.75 30.0289 239.998 30.0289C205.246 30.0289 177.073 58.156 177.073 92.8526C177.073 127.549 205.246 155.676 239.998 155.676Z"
      fill="black"
    />
    <path
      opacity="0.05"
      d="M239.998 137.896C264.914 137.896 285.113 117.729 285.113 92.8526C285.113 67.9758 264.914 47.8092 239.998 47.8092C215.081 47.8092 194.882 67.9758 194.882 92.8526C194.882 117.729 215.081 137.896 239.998 137.896Z"
      fill="black"
    />
    <path
      d="M147.819 101.162C147.819 101.162 140.508 121.711 143.757 129.011C147.007 136.311 152.152 143.341 152.152 143.341C152.152 143.341 150.257 102.514 147.819 101.162Z"
      fill="#D0CDE1"
    />
    <path
      opacity="0.1"
      d="M147.819 101.162C147.819 101.162 140.508 121.711 143.757 129.011C147.007 136.311 152.152 143.341 152.152 143.341C152.152 143.341 150.257 102.514 147.819 101.162Z"
      fill="black"
    />
    <path
      d="M152.966 148.748C152.966 148.748 152.424 153.885 152.153 154.155C151.882 154.426 152.424 154.967 152.153 155.778C151.882 156.589 151.611 157.67 152.153 157.941C152.695 158.211 149.174 182.004 149.174 182.004C149.174 182.004 140.508 193.36 144.029 211.204L145.112 229.319C145.112 229.319 153.507 229.86 153.507 226.886C153.507 226.886 152.966 223.371 152.966 221.749C152.966 220.126 154.32 220.126 153.507 219.315C152.695 218.504 152.695 217.964 152.695 217.964C152.695 217.964 154.049 216.882 153.778 216.612C153.507 216.341 156.215 197.145 156.215 197.145C156.215 197.145 159.194 194.171 159.194 192.548V190.926C159.194 190.926 160.548 187.411 160.548 187.141C160.548 186.871 167.86 170.378 167.86 170.378L170.839 182.274L174.089 199.308C174.089 199.308 175.713 214.719 178.963 220.667C178.963 220.667 184.65 240.134 184.65 239.593C184.65 239.053 194.128 237.701 193.858 235.267C193.587 232.834 188.171 198.767 188.171 198.767L189.525 148.207L152.966 148.748Z"
      fill="#2F2E41"
    />
    <path
      d="M145.654 227.967C145.654 227.967 138.342 242.297 143.217 242.838C148.091 243.379 149.987 243.379 152.153 241.216C153.338 240.033 155.736 238.447 157.667 237.251C158.812 236.554 159.736 235.55 160.334 234.352C160.933 233.155 161.181 231.813 161.051 230.481C160.908 229.159 160.413 228.069 159.194 227.967C155.945 227.697 152.153 224.723 152.153 224.723L145.654 227.967Z"
      fill="#2F2E41"
    />
    <path
      d="M186.004 238.512C186.004 238.512 178.692 252.842 183.566 253.382C188.441 253.923 190.336 253.923 192.503 251.76C193.687 250.578 196.086 248.991 198.017 247.796C199.161 247.099 200.085 246.094 200.684 244.897C201.282 243.699 201.531 242.358 201.4 241.026C201.258 239.703 200.763 238.613 199.544 238.512C196.294 238.241 192.503 235.267 192.503 235.267L186.004 238.512Z"
      fill="#2F2E41"
    />
    <path
      d="M178.276 77.712C184.563 77.712 189.66 72.6235 189.66 66.3465C189.66 60.0695 184.563 54.981 178.276 54.981C171.989 54.981 166.893 60.0695 166.893 66.3465C166.893 72.6235 171.989 77.712 178.276 77.712Z"
      fill="#FFB8B8"
    />
    <path
      d="M170.958 70.0809C170.958 70.0809 162.827 85.0184 162.176 85.0184C161.526 85.0184 176.813 89.8893 176.813 89.8893C176.813 89.8893 181.041 75.6013 181.691 74.3023L170.958 70.0809Z"
      fill="#FFB8B8"
    />
    <path
      d="M183.431 86.427C183.431 86.427 167.183 77.5046 165.558 77.775C163.933 78.0454 146.601 93.1863 146.872 99.4049C147.143 105.623 149.31 115.898 149.31 115.898C149.31 115.898 150.122 144.557 151.747 144.828C153.372 145.098 151.476 149.965 152.018 149.965C152.559 149.965 189.931 149.965 190.202 149.154C190.472 148.342 183.431 86.427 183.431 86.427Z"
      fill="#D0CDE1"
    />
    <path
      d="M192.503 150.641C192.503 150.641 197.648 166.322 193.315 165.781C188.983 165.241 187.087 152.263 187.087 152.263L192.503 150.641Z"
      fill="#FFB8B8"
    />
    <path
      d="M178.692 85.4807C178.692 85.4807 168.673 87.6436 170.297 101.162C171.922 114.681 174.901 128.2 174.901 128.2L184.921 150.1L186.004 154.155L193.316 152.263L187.9 120.9C187.9 120.9 186.004 87.3733 183.567 86.2918C182.03 85.6376 180.359 85.3595 178.692 85.4807Z"
      fill="#D0CDE1"
    />
    <path
      opacity="0.1"
      d="M172.599 127.794L185.056 149.965L174.56 126.603L172.599 127.794Z"
      fill="black"
    />
    <path
      d="M189.609 62.9999L189.647 62.1314L191.378 62.5614C191.359 62.2826 191.28 62.0111 191.146 61.7657C191.012 61.5203 190.827 61.3069 190.602 61.1401L192.445 61.0372C190.899 58.8427 188.922 56.984 186.636 55.5733C184.35 54.1626 181.801 53.2292 179.143 52.8294C175.156 52.2524 170.716 53.0874 167.982 56.0417C166.656 57.4746 165.823 59.297 165.23 61.1561C164.138 64.5801 163.916 68.6618 166.192 71.4451C168.506 74.2738 172.547 74.828 176.188 75.178C177.47 75.3012 178.812 75.4158 179.999 74.9196C180.131 73.56 179.957 72.1882 179.489 70.9046C179.294 70.5073 179.201 70.0679 179.218 69.6258C179.38 68.5443 180.825 68.2719 181.91 68.418C182.996 68.5641 184.301 68.7876 185.014 67.9578C185.505 67.3861 185.476 66.5537 185.541 65.8033C185.719 63.7603 189.591 63.4282 189.609 62.9999Z"
      fill="#2F2E41"
    />
    <path
      d="M259.431 242.525C266.756 242.525 272.695 236.597 272.695 229.283C272.695 221.969 266.756 216.04 259.431 216.04C252.105 216.04 246.167 221.969 246.167 229.283C246.167 236.597 252.105 242.525 259.431 242.525Z"
      fill="#6C63FF"
    />
    <path
      d="M103.658 237.906C110.983 237.906 116.921 231.977 116.921 224.663C116.921 217.35 110.983 211.421 103.658 211.421C96.332 211.421 90.3936 217.35 90.3936 224.663C90.3936 231.977 96.332 237.906 103.658 237.906Z"
      fill="#6C63FF"
    />
    <path
      d="M103.657 216.656C108.938 216.656 113.219 212.382 113.219 207.109C113.219 201.836 108.938 197.562 103.657 197.562C98.376 197.562 94.0947 201.836 94.0947 207.109C94.0947 212.382 98.376 216.656 103.657 216.656Z"
      fill="#6C63FF"
    />
    <path
      d="M103.657 199.102C107.405 199.102 110.444 196.068 110.444 192.327C110.444 188.585 107.405 185.551 103.657 185.551C99.9094 185.551 96.8711 188.585 96.8711 192.327C96.8711 196.068 99.9094 199.102 103.657 199.102Z"
      fill="#6C63FF"
    />
  </g>
  <defs>
    <clipPath id="clip0_2678_25730">
      <rect width="246" height="257" fill="white" transform="translate(87)" />
    </clipPath>
  </defs>
</svg>
<gls-alert [color]="'error'" style="width: 440px">
  <span alert-text>
    <b>Sorry</b>
    <br />
    <span>No record found for the provided search criteria.</span>
  </span>
</gls-alert>
