import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, startWith } from 'rxjs';
import { NavigationEnd, ParamMap, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class RouteTrackerService {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  private _url$ = new BehaviorSubject<string>('');
  private _paramMap$ = new BehaviorSubject<ParamMap | null>(null);

  public constructor() {
    this.trackUrlChanges();
  }

  public get paramMap$(): Observable<ParamMap | null> {
    return this._paramMap$.asObservable();
  }

  public set paramMap(paramMap: ParamMap | null) {
    this._paramMap$.next(paramMap);
  }

  public get paramMap(): ParamMap | null {
    return this._paramMap$.value;
  }

  public get url$(): Observable<string> {
    return this._url$.asObservable();
  }

  private trackUrlChanges(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(new NavigationEnd(0, this.router.url, this.router.url)),
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects),
      )
      .subscribe((url) => {
        this._url$.next(url);
      });
  }
}
