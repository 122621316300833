<app-layout>
  <div class="containerr">
    <div class="filters-example">
      <form class="form-filters" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <gls-filters>
          <gls-search-input formControlName="searchTerm" (keyup.enter)="onSearchHumanUserClick()" placeholder="username@email.com"></gls-search-input>
          <ng-container buttonGroup>
            <gls-filter-single-select formControlName="searchBy" [buttonLabel]="formGroup.value['searchBy']">
              <gls-filter-single-select-option ngValue="human-account" [label]="'Human Account'"> </gls-filter-single-select-option>
              <gls-filter-single-select-option ngValue="application-id" [label]="'Technical Account'"> </gls-filter-single-select-option>
            </gls-filter-single-select>
          </ng-container>
        </gls-filters>
      </form>
      <gls-filter-results [formGroup]="formGroup" [enableClearAll]="true"></gls-filter-results>
    </div>
    <div *ngIf="state === 'nothing-searched'" class="svg-icon-big">
      <svg width="420" height="256" viewBox="0 0 420 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2679_26305)">
          <path d="M329.165 39.9863C329.165 39.9863 319.947 41.0262 319.351 55.3111C318.854 67.216 317.841 73.8498 322.684 76.3329C324.413 77.2204 326.446 77.2966 328.296 76.6915L348.051 70.1922C348.051 70.1922 348.346 48.767 341.506 43.5586C334.666 38.3502 329.165 39.9863 329.165 39.9863Z" fill="#36344E"/>
          <path d="M340.055 72.8189L330.057 76.1088V61.7073H339.128L340.055 72.8189Z" fill="#FAAFB2"/>
          <path d="M309.492 154.566C308.95 157.865 306.751 160.232 304.582 159.851C302.41 159.47 301.093 156.485 301.635 153.186C301.832 151.864 302.356 150.613 303.158 149.542L305.635 135.593L312.377 137.005L309.219 150.604C309.635 151.89 309.729 153.249 309.492 154.566Z" fill="#FAAFB2"/>
          <path d="M332.382 247.318H323.02L317.586 197.251H332.064L332.382 247.318Z" fill="#FAAFB2"/>
          <path d="M313.9 255.363C312.914 255.363 312.037 255.341 311.378 255.278C308.892 255.05 306.518 253.212 305.326 252.141C304.793 251.661 304.619 250.89 304.896 250.227C305.098 249.752 305.497 249.398 305.994 249.254L312.565 247.376L323.208 240.191L323.329 240.406C323.374 240.487 324.418 242.369 324.767 243.642C324.901 244.126 324.866 244.53 324.664 244.844C324.525 245.059 324.328 245.184 324.171 245.256C324.364 245.458 324.969 245.866 326.828 246.161C329.547 246.592 330.111 243.777 330.138 243.66L330.156 243.566L330.236 243.512C331.526 242.679 332.324 242.302 332.597 242.383C332.767 242.432 333.054 242.522 333.824 250.186C333.901 250.429 334.443 252.19 334.075 253.88C333.677 255.718 325.663 255.086 324.059 254.938C324.014 254.942 318.017 255.368 313.904 255.368H313.9V255.363Z" fill="#36344E"/>
          <path d="M353.538 247.318H344.172L338.743 197.251H353.216L353.538 247.318Z" fill="#FAAFB2"/>
          <path d="M335.056 255.363C334.071 255.363 333.193 255.341 332.534 255.278C330.048 255.05 327.674 253.212 326.483 252.141C325.95 251.661 325.775 250.89 326.053 250.227C326.254 249.752 326.653 249.398 327.15 249.254L333.721 247.376L344.364 240.191L344.485 240.406C344.53 240.487 345.574 242.369 345.923 243.642C346.058 244.126 346.022 244.53 345.82 244.844C345.681 245.059 345.484 245.184 345.327 245.256C345.52 245.458 346.125 245.866 347.984 246.161C350.703 246.592 351.267 243.777 351.294 243.66L351.312 243.566L351.393 243.512C352.687 242.679 353.48 242.302 353.753 242.383C353.923 242.432 354.21 242.522 354.981 250.186C355.057 250.429 355.599 252.19 355.231 253.88C354.833 255.718 346.819 255.086 345.216 254.938C345.171 254.942 339.173 255.368 335.061 255.368H335.056V255.363Z" fill="#36344E"/>
          <path d="M356.23 124.347H310.733L327.087 70.4881H345.229L356.23 124.347Z" fill="#DFDFE0"/>
          <path d="M329.949 70.4926C329.949 70.4926 318.648 70.1967 317.461 73.173C316.274 76.1492 302.293 146.973 302.293 146.973H311.512L329.949 70.497V70.4926Z" fill="#DFDFE0"/>
          <path d="M331.293 65.692C336.795 65.692 341.255 61.229 341.255 55.7235C341.255 50.218 336.795 45.7549 331.293 45.7549C325.791 45.7549 321.331 50.218 321.331 55.7235C321.331 61.229 325.791 65.692 331.293 65.692Z" fill="#FAAFB2"/>
          <path d="M328.57 43.7066C328.57 43.7066 323.087 65.9834 329.184 76.3957L320.597 74.5714C320.597 74.5714 317.268 50.1924 323.961 45.7594L328.57 43.7066Z" fill="#36344E"/>
          <path d="M346.774 124.347H316.914L300.578 191.442C300.278 194.275 302.074 196.919 304.825 197.659C313.788 200.061 335.298 203.463 367.85 193.983C370.981 193.074 372.759 189.766 371.778 186.65L346.774 124.347Z" fill="#36344E"/>
          <path d="M334.702 70.4881L343.988 70.0802C348.01 70.385 351.648 72.5723 353.802 75.9833C360.365 86.3643 372.643 108.242 361.682 111.241C347.231 115.19 340.23 93.2042 340.23 93.2042L334.702 70.4881Z" fill="#DFDFE0"/>
          <path d="M331.517 77.0053C329.609 74.2621 329.542 71.0304 331.36 69.7888C333.184 68.5473 336.207 69.7709 338.115 72.5141C338.895 73.5988 339.388 74.8628 339.558 76.1895L347.491 87.9241L341.672 91.613L334.469 79.6543C333.273 79.0223 332.261 78.1124 331.517 77.0053Z" fill="#FAAFB2"/>
          <path d="M336.212 86.4942L342.765 80.4611L355.724 92.5319L343.675 100.775L336.212 86.4942Z" fill="#DFDFE0"/>
          <path d="M15.1096 56.7589H220.831C228.849 56.7589 235.349 63.2627 235.349 71.2859C235.349 79.3092 228.849 85.8129 220.831 85.8129H15.1096C7.09142 85.8129 0.591797 79.3092 0.591797 71.2859C0.591797 63.2627 7.09142 56.7589 15.1096 56.7589Z" fill="#DEEBFE"/>
          <path d="M91.6626 255.467C91.6626 255.762 91.9 256 92.1956 256H418.875C419.17 256 419.408 255.762 419.408 255.467C419.408 255.171 419.17 254.933 418.875 254.933H92.1956C91.9 254.933 91.6626 255.171 91.6626 255.467Z" fill="#484565"/>
          <path d="M190.797 42.1109H233.508C234.529 42.1109 235.353 42.9356 235.353 43.9531C235.353 44.9705 234.529 45.7953 233.508 45.7953H190.797C189.775 45.7953 188.951 44.9705 188.951 43.9531C188.951 42.9356 189.775 42.1109 190.797 42.1109Z" fill="#DFDFE0"/>
          <path d="M2.43731 0H45.1485C46.1698 0 46.994 0.824737 46.994 1.84221C46.994 2.85968 46.1698 3.68442 45.1485 3.68442H2.43731C1.41601 3.6889 0.591797 2.86417 0.591797 1.84221C0.591797 0.824737 1.41601 0 2.43731 0Z" fill="#DFDFE0"/>
          <path d="M2.43731 16.0958H127.803C128.824 16.0958 129.648 16.9205 129.648 17.9425C129.648 18.96 128.824 19.7847 127.803 19.7847H2.43731C1.41601 19.7847 0.591797 18.96 0.591797 17.9425C0.591797 16.925 1.41601 16.0958 2.43731 16.0958Z" fill="#DFDFE0"/>
          <path d="M105.307 116.933C105.307 126.597 113.137 134.432 122.795 134.432C125.72 134.432 128.475 133.715 130.894 132.446L147.839 159.34C148.878 160.985 151.096 161.397 152.655 160.232C154.11 159.143 154.424 157.09 153.362 155.611L134.723 129.726C138.141 126.53 140.282 121.98 140.282 116.933C140.282 107.27 132.452 99.4345 122.795 99.4345C113.137 99.4345 105.307 107.27 105.307 116.933ZM107.708 116.933C107.708 108.596 114.463 101.833 122.799 101.833C131.135 101.833 137.89 108.592 137.89 116.933C137.89 125.27 131.135 132.034 122.799 132.034C114.468 132.034 107.708 125.275 107.708 116.933Z" fill="#484662"/>
        </g>
        <defs>
          <clipPath id="clip0_2679_26305">
            <rect width="418.816" height="256" fill="white" transform="translate(0.591797)"/>
          </clipPath>
        </defs>
      </svg>
      <gls-alert [color]="'info'" style="width: 440px">
        <span alert-text>
          <b>Enter an email to search</b>
          <br>
          Make sure the email provided is valid.
        </span>
      </gls-alert>
    </div>
    <div *ngIf="state === 'not-found'" class="svg-icon-big">
      <svg width="420" height="257" viewBox="0 0 420 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2678_25730)">
          <path d="M182.161 257C234.717 257 277.322 249.485 277.322 240.216C277.322 230.946 234.717 223.431 182.161 223.431C129.605 223.431 87 230.946 87 240.216C87 249.485 129.605 257 182.161 257Z" fill="#3F3D56"/>
          <path d="M239.998 185.705C291.361 185.705 333 144.134 333 92.8526C333 41.5715 291.361 0 239.998 0C188.634 0 146.996 41.5715 146.996 92.8526C146.996 144.134 188.634 185.705 239.998 185.705Z" fill="#3F3D56"/>
          <path opacity="0.05" d="M239.998 169.505C282.4 169.505 316.774 135.187 316.774 92.8526C316.774 50.5184 282.4 16.1998 239.998 16.1998C197.595 16.1998 163.222 50.5184 163.222 92.8526C163.222 135.187 197.595 169.505 239.998 169.505Z" fill="black"/>
          <path opacity="0.05" d="M239.998 155.676C274.75 155.676 302.923 127.549 302.923 92.8526C302.923 58.156 274.75 30.0289 239.998 30.0289C205.246 30.0289 177.073 58.156 177.073 92.8526C177.073 127.549 205.246 155.676 239.998 155.676Z" fill="black"/>
          <path opacity="0.05" d="M239.998 137.896C264.914 137.896 285.113 117.729 285.113 92.8526C285.113 67.9758 264.914 47.8092 239.998 47.8092C215.081 47.8092 194.882 67.9758 194.882 92.8526C194.882 117.729 215.081 137.896 239.998 137.896Z" fill="black"/>
          <path d="M147.819 101.162C147.819 101.162 140.508 121.711 143.757 129.011C147.007 136.311 152.152 143.341 152.152 143.341C152.152 143.341 150.257 102.514 147.819 101.162Z" fill="#D0CDE1"/>
          <path opacity="0.1" d="M147.819 101.162C147.819 101.162 140.508 121.711 143.757 129.011C147.007 136.311 152.152 143.341 152.152 143.341C152.152 143.341 150.257 102.514 147.819 101.162Z" fill="black"/>
          <path d="M152.966 148.748C152.966 148.748 152.424 153.885 152.153 154.155C151.882 154.426 152.424 154.967 152.153 155.778C151.882 156.589 151.611 157.67 152.153 157.941C152.695 158.211 149.174 182.004 149.174 182.004C149.174 182.004 140.508 193.36 144.029 211.204L145.112 229.319C145.112 229.319 153.507 229.86 153.507 226.886C153.507 226.886 152.966 223.371 152.966 221.749C152.966 220.126 154.32 220.126 153.507 219.315C152.695 218.504 152.695 217.964 152.695 217.964C152.695 217.964 154.049 216.882 153.778 216.612C153.507 216.341 156.215 197.145 156.215 197.145C156.215 197.145 159.194 194.171 159.194 192.548V190.926C159.194 190.926 160.548 187.411 160.548 187.141C160.548 186.871 167.86 170.378 167.86 170.378L170.839 182.274L174.089 199.308C174.089 199.308 175.713 214.719 178.963 220.667C178.963 220.667 184.65 240.134 184.65 239.593C184.65 239.053 194.128 237.701 193.858 235.267C193.587 232.834 188.171 198.767 188.171 198.767L189.525 148.207L152.966 148.748Z" fill="#2F2E41"/>
          <path d="M145.654 227.967C145.654 227.967 138.342 242.297 143.217 242.838C148.091 243.379 149.987 243.379 152.153 241.216C153.338 240.033 155.736 238.447 157.667 237.251C158.812 236.554 159.736 235.55 160.334 234.352C160.933 233.155 161.181 231.813 161.051 230.481C160.908 229.159 160.413 228.069 159.194 227.967C155.945 227.697 152.153 224.723 152.153 224.723L145.654 227.967Z" fill="#2F2E41"/>
          <path d="M186.004 238.512C186.004 238.512 178.692 252.842 183.566 253.382C188.441 253.923 190.336 253.923 192.503 251.76C193.687 250.578 196.086 248.991 198.017 247.796C199.161 247.099 200.085 246.094 200.684 244.897C201.282 243.699 201.531 242.358 201.4 241.026C201.258 239.703 200.763 238.613 199.544 238.512C196.294 238.241 192.503 235.267 192.503 235.267L186.004 238.512Z" fill="#2F2E41"/>
          <path d="M178.276 77.712C184.563 77.712 189.66 72.6235 189.66 66.3465C189.66 60.0695 184.563 54.981 178.276 54.981C171.989 54.981 166.893 60.0695 166.893 66.3465C166.893 72.6235 171.989 77.712 178.276 77.712Z" fill="#FFB8B8"/>
          <path d="M170.958 70.0809C170.958 70.0809 162.827 85.0184 162.176 85.0184C161.526 85.0184 176.813 89.8893 176.813 89.8893C176.813 89.8893 181.041 75.6013 181.691 74.3023L170.958 70.0809Z" fill="#FFB8B8"/>
          <path d="M183.431 86.427C183.431 86.427 167.183 77.5046 165.558 77.775C163.933 78.0454 146.601 93.1863 146.872 99.4049C147.143 105.623 149.31 115.898 149.31 115.898C149.31 115.898 150.122 144.557 151.747 144.828C153.372 145.098 151.476 149.965 152.018 149.965C152.559 149.965 189.931 149.965 190.202 149.154C190.472 148.342 183.431 86.427 183.431 86.427Z" fill="#D0CDE1"/>
          <path d="M192.503 150.641C192.503 150.641 197.648 166.322 193.315 165.781C188.983 165.241 187.087 152.263 187.087 152.263L192.503 150.641Z" fill="#FFB8B8"/>
          <path d="M178.692 85.4807C178.692 85.4807 168.673 87.6436 170.297 101.162C171.922 114.681 174.901 128.2 174.901 128.2L184.921 150.1L186.004 154.155L193.316 152.263L187.9 120.9C187.9 120.9 186.004 87.3733 183.567 86.2918C182.03 85.6376 180.359 85.3595 178.692 85.4807Z" fill="#D0CDE1"/>
          <path opacity="0.1" d="M172.599 127.794L185.056 149.965L174.56 126.603L172.599 127.794Z" fill="black"/>
          <path d="M189.609 62.9999L189.647 62.1314L191.378 62.5614C191.359 62.2826 191.28 62.0111 191.146 61.7657C191.012 61.5203 190.827 61.3069 190.602 61.1401L192.445 61.0372C190.899 58.8427 188.922 56.984 186.636 55.5733C184.35 54.1626 181.801 53.2292 179.143 52.8294C175.156 52.2524 170.716 53.0874 167.982 56.0417C166.656 57.4746 165.823 59.297 165.23 61.1561C164.138 64.5801 163.916 68.6618 166.192 71.4451C168.506 74.2738 172.547 74.828 176.188 75.178C177.47 75.3012 178.812 75.4158 179.999 74.9196C180.131 73.56 179.957 72.1882 179.489 70.9046C179.294 70.5073 179.201 70.0679 179.218 69.6258C179.38 68.5443 180.825 68.2719 181.91 68.418C182.996 68.5641 184.301 68.7876 185.014 67.9578C185.505 67.3861 185.476 66.5537 185.541 65.8033C185.719 63.7603 189.591 63.4282 189.609 62.9999Z" fill="#2F2E41"/>
          <path d="M259.431 242.525C266.756 242.525 272.695 236.597 272.695 229.283C272.695 221.969 266.756 216.04 259.431 216.04C252.105 216.04 246.167 221.969 246.167 229.283C246.167 236.597 252.105 242.525 259.431 242.525Z" fill="#6C63FF"/>
          <path d="M103.658 237.906C110.983 237.906 116.921 231.977 116.921 224.663C116.921 217.35 110.983 211.421 103.658 211.421C96.332 211.421 90.3936 217.35 90.3936 224.663C90.3936 231.977 96.332 237.906 103.658 237.906Z" fill="#6C63FF"/>
          <path d="M103.657 216.656C108.938 216.656 113.219 212.382 113.219 207.109C113.219 201.836 108.938 197.562 103.657 197.562C98.376 197.562 94.0947 201.836 94.0947 207.109C94.0947 212.382 98.376 216.656 103.657 216.656Z" fill="#6C63FF"/>
          <path d="M103.657 199.102C107.405 199.102 110.444 196.068 110.444 192.327C110.444 188.585 107.405 185.551 103.657 185.551C99.9094 185.551 96.8711 188.585 96.8711 192.327C96.8711 196.068 99.9094 199.102 103.657 199.102Z" fill="#6C63FF"/>
        </g>
        <defs>
          <clipPath id="clip0_2678_25730">
            <rect width="246" height="257" fill="white" transform="translate(87)"/>
          </clipPath>
        </defs>
      </svg>
      <gls-alert [color]="'error'" style="width: 440px">
        <span alert-text>
          <b>Sorry, something went wrong.</b>
          <br>
          {{errorMessage || 'Not records were found for the provided search.'}}
        </span>
      </gls-alert>
    </div>
    <div *ngIf="state === 'found'">
      <br>
      <gls-card [interactive]="false">
        <ng-template glsTemplate="header">
          <div class="card-header">
            <h4 class="card-header-title">Common Attributes</h4>
          </div>
        </ng-template>
        <ng-template glsTemplate="content">
          <div class="form-container" [formGroup]="formResults">
            <div class="right">
              <div class="two-columns">
                <gls-text-input formControlName="firstname" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">First Name</ng-template>
                </gls-text-input>
                <gls-text-input formControlName="lastname" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Last Name</ng-template>
                </gls-text-input>
              </div>
              <div class="two-columns">
                <gls-text-input formControlName="firstname" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Email</ng-template>
                </gls-text-input>
                <gls-toggle-switch-multiple formControlName="status">
                  <ng-template glsTemplate="labelSlot" >
                    <div class="text-label">Status</div>
                  </ng-template>
                  <ng-template glsTemplate="optionsSlot">
                    <gls-toggle-switch-multiple-option [value]="'active'">
                      Active
                    </gls-toggle-switch-multiple-option>
                    <gls-toggle-switch-multiple-option [value]="'inactive'">
                      Inactive
                    </gls-toggle-switch-multiple-option>
                  </ng-template>
                </gls-toggle-switch-multiple>
              </div>
            </div>
          </div>
        </ng-template>
      </gls-card>
      <br>
      <gls-card [interactive]="false">
        <ng-template glsTemplate="header">
          <div class="card-header">
            <h4 class="card-header-title">App Related Attributes</h4>
          </div>
        </ng-template>
        <ng-template glsTemplate="content">
          <div class="form-container" [formGroup]="formAppRelatedAttributes">
            <div class="right">
              <div class="one-column">
                <gls-text-input formControlName="app" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Apps</ng-template>
                </gls-text-input>
              </div>

              <div class="two-columns">
                <gls-text-input formControlName="countryCode" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Country Code</ng-template>
                </gls-text-input>
                <gls-text-input formControlName="locationCode" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Location Code</ng-template>
                </gls-text-input>
              </div>
              <div class="two-columns">
                <gls-text-input formControlName="companyName" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Company Name</ng-template>
                </gls-text-input>
                <div></div>
              </div>
              <div class="two-columns">
                <gls-text-input formControlName="csspCustomerIdentification" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">CSSP Customer Identification</ng-template>
                </gls-text-input>
                <gls-text-input formControlName="enterpriseId" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Enterprise Id</ng-template>
                </gls-text-input>
              </div>
              <div class="two-columns">
                <gls-text-input formControlName="customerId" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Customer Id</ng-template>
                </gls-text-input>
                <gls-text-input formControlName="contactId" autocomplete="off">
                  <ng-template glsTemplate="labelSlot">Contact Id</ng-template>
                </gls-text-input>
              </div>

            </div>
          </div>
        </ng-template>
      </gls-card>
    </div>
  </div>
</app-layout>
