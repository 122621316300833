import { inject, Injectable } from '@angular/core';
import { validateWithSchema } from '@shared/rxjs-operators/validate-with-schema';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AppGetRequestRespSchema } from '@models/business/app/requests/app-get-request-resp.schema';
import { validateWithAttributesMetadata } from '@shared/rxjs-operators/validate-with-attributes-metadata';
import { ExtensionsDefinitionGetRequestRespSchema } from '@models/business/extension-definition/requests/extensions-definition-get-request.schema';
import { ReplaySubject, tap } from 'rxjs';
import { ExtensionDefinition } from '@models/business/extension-definition/extension-definition.schema';
import { AppPutRequestBody } from '@models/business/app/requests/app-put-request-body.schema';
import { AppPutRequestRespSchema } from '@models/business/app/requests/app-put-request-resp.schema';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  public static APP_EXTENSIONS_DEFINITIONS = new ReplaySubject<
    Array<ExtensionDefinition>
  >(1);

  getApp(id: string) {
    return (
      this.http
        .get(`${this.BASE_URL}/usermanagement/external/scim/v2/Apps/${id}`)
        // .get(`/assets/mock-data/app-get-request-response.mock.json`)
        .pipe(
          validateWithSchema(AppGetRequestRespSchema),
          validateWithAttributesMetadata(this.getAppExtensionsDefinitions()),
        )
    );
  }

  updateApp(id: string, payload: AppPutRequestBody) {
    const url = `${this.BASE_URL}/usermanagement/external/scim/v2/Apps/${id}`;
    return this.http
      .put(url, payload)
      .pipe(
        validateWithSchema(AppPutRequestRespSchema),
        validateWithAttributesMetadata(this.getAppExtensionsDefinitions()),
      );
  }

  getAppExtensionsDefinitions() {
    return this.http
      .get('/assets/mock-data/extensions-definitions-app.json')
      .pipe(
        validateWithSchema(ExtensionsDefinitionGetRequestRespSchema),
        tap((data) => AppService.APP_EXTENSIONS_DEFINITIONS.next(data)),
      );
  }
}
