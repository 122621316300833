import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HumanUsersTableComponent } from './human-users/human-users-table/human-users-table.component';
import { BasicAttributesComponent } from './human-users/basic-attributes/basic-attributes.component';
import { RelatedAttributesComponent } from './human-users/related-attributes/related-attributes.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { TableComponent } from './components/table/table.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { ApplicationsComponent } from './applications/applications.component';
import { RolesComponent } from './roles/roles.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalInterceptor } from '@azure/msal-angular';
import { MsalGuard } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HomeComponent } from './home/home.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { authConfig } from './auth-config';
import { environment } from './environments/environment';
import { WINDOW_PROVIDER } from './services/providers/window.provider';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  corePlatformUiTranslateLoader,
  CorePlatformUiTranslateModule,
  FilterResultsComponent,
  FiltersComponent,
  FilterSingleSelectComponent,
  FilterSingleSelectOptionComponent,
  GLSTemplateDirective,
  IconButtonComponent,
  SearchInputComponent,
  TextInputComponent,
  ToggleSwitchMultipleComponent,
  ToggleSwitchMultipleOptionComponent,
} from '@gls/platform-ui';
import { translationsLoader } from './translations/translations.loader';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { TokenInterceptor } from './interceptors/token-interceptor/token-interceptor.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HumanUsersTableComponent,
    BasicAttributesComponent,
    RelatedAttributesComponent,
    DropdownComponent,
    TableComponent,
    SwitchButtonComponent,
    ApplicationsComponent,
    RolesComponent,
    MultiSelectDropdownComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    CorePlatformUiTranslateModule.forRoot({
      loaders: [corePlatformUiTranslateLoader, translationsLoader],
      defaultLocale: 'en',
      supportedLocales: ['en'],
      fallbackLocales: [],
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: authConfig.clientId,
          authority: authConfig.authority,
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: authConfig.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', authConfig.scopes],
        ]),
      },
    ),
    AppLayoutComponent,
    FilterResultsComponent,
    FilterSingleSelectComponent,
    FilterSingleSelectOptionComponent,
    FiltersComponent,
    ReactiveFormsModule,
    SearchInputComponent,
    AlertComponent,
    TextInputComponent,
    GLSTemplateDirective,
    CardComponent,
    ButtonComponent,
    IconButtonComponent,
    ToggleSwitchMultipleComponent,
    ToggleSwitchMultipleOptionComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MsalGuard,
    // AzureAdDemoService,
    WINDOW_PROVIDER,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [],
})
export class AppModule {}
