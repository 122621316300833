import { z } from 'zod';

export const PageSchema = <T extends z.ZodTypeAny>(resourceSchema: T) =>
  z.object({
    schemas: z.object({
      schemas: z.array(z.string()),
    }),
    startIndex: z.number(),
    itemsPerPage: z.number(),
    totalResults: z.number(),
    resources: z.array(resourceSchema),
  });

export type Page<T extends z.ZodTypeAny> = z.infer<
  ReturnType<typeof PageSchema<T>>
>;
