import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UserService } from '@services/api/user.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  FilterResultsComponent,
  FiltersComponent,
  FilterSingleSelectComponent,
  FilterSingleSelectOptionComponent,
  SearchInputComponent,
  ToastService,
} from '@gls/platform-ui';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from '../../../../layout/app-layout/app-layout.component';
import {
  CORE_USER_ID,
  USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
} from '@models/business/scim-schemas/scim-schema-id';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  noop,
  startWith,
  Subscription,
} from 'rxjs';
import { FormCommonAttributesComponent } from '@shared/forms/form-common-attributes/form-common-attributes.component';
import { FormGroupAebCustomsComponent } from '@shared/forms/form-group-aeb-customs/form-group-aeb-customs.component';
import {
  AsyncData,
  withAsyncData,
} from '@shared/rxjs-operators/with-async-data';
import { MessageSearchSomethingComponent } from './components/message-search-something/message-search-something.component';
import { MessageSearchNotFoundComponent } from './components/message-search-not-found/message-search-not-found.component';
import { asCsspCustomerIdentificationAttribute } from '@models/business/scim-schemas/scim_schema__group-aeb-customs';
import { withAsyncAction } from '@shared/rxjs-operators/with-async-action';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UserResource,
  UserResourceSchema,
} from '@models/business/user/requests/user-get-request-resp.schema';
import { AppService } from '@services/api/app.service';
import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import { FormAppCoreComponent } from '@shared/forms/form-app-core/form-app-core.component';
import { extractExtensionsFromObject } from '@shared/utils-form/extract-extensions-from-object';
import { ExtensionData } from '@models/business/extensionDataSchema';
import { FormAppComponent } from './components/form-app/form-app.component';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { withAsyncDataV2 } from '@xbp-commons/async-data/rxjs-operators/with-async-data-v2.operator';
import { ActionTrackerService } from '@services/trackers/action-tracker.service';

type SearchBy = 'Human Account' | 'Technical Account';
export type AsyncActions = 'searching' | 'updating';

@Component({
  selector: 'page-human-account-search',
  standalone: true,
  templateUrl: './accounts-search.page.html',
  styleUrls: ['./accounts-search.page.scss'],
  imports: [
    CommonModule,
    AppLayoutComponent,
    ReactiveFormsModule,
    FiltersComponent,
    SearchInputComponent,
    FilterSingleSelectComponent,
    FilterSingleSelectOptionComponent,
    FilterResultsComponent,
    AppLayoutComponent,
    ButtonComponent,
    FormCommonAttributesComponent,
    FormGroupAebCustomsComponent,
    MessageSearchSomethingComponent,
    MessageSearchNotFoundComponent,
    FormAppComponent,
  ],
})
export class AccountsSearchPage implements OnInit, AfterViewInit {
  @ViewChildren(FormCommonAttributesComponent)
  formCommonAttributesComponents: QueryList<FormCommonAttributesComponent>;
  @ViewChildren(FormGroupAebCustomsComponent)
  formGroupAebCustomsComponents: QueryList<FormGroupAebCustomsComponent>;

  @ViewChildren(FormAppCoreComponent)
  formAppCoreComponents: QueryList<FormAppCoreComponent>;

  public searchForm = new FormGroup({
    searchTerm: new FormControl<string>('', { nonNullable: true }),
    searchBy: new FormControl<SearchBy>('Human Account', { nonNullable: true }),
  });

  schemasForms = new FormGroup({
    welcomeEmail: new FormControl(true),
    groupAEBCustoms: new FormControl(true),
  });

  asyncAction = this.actionTrackerService.action;
  asyncUserOrApp = new AsyncData<UserResource | AppGetRequestResp>();

  asyncExtensions = new AsyncData<Map<string, ExtensionData>>();
  USER_EXTENSIONS_DEFINITIONS = UserService.USER_EXTENSIONS_DEFINITIONS;
  APP_EXTENSIONS_DEFINITIONS = AppService.APP_EXTENSIONS_DEFINITIONS;

  hasFormChanges = new BehaviorSubject(false);

  whatToShow: 'message-search-something' | 'message-search-not-found' | 'form' =
    'message-search-something';

  constructor(
    private userService: UserService,
    private appService: AppService,
    private appTrackerService: AppTrackerService,
    private actionTrackerService: ActionTrackerService,
    private changeDetector: ChangeDetectorRef,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.trackGetHumanUsersAsync();
    this.SyncQueryParamsAndForm();
    this.keepWhatToShowUpdated();
    this.trackSearchByChanges();
  }

  ngAfterViewInit(): void {
    this.trackFormsChanges();
  }

  onSubmitSearch() {
    if (this.searchForm.controls.searchBy.value === 'Human Account')
      this.searchByUser();

    if (this.searchForm.controls.searchBy.value === 'Technical Account')
      this.searchByApp();
  }

  private searchByUser() {
    this.userService
      .getUser(this.searchForm.controls.searchTerm.value)
      .pipe(
        withAsyncAction(this.asyncAction, 'searching'),
        withAsyncData(this.asyncUserOrApp, (data) => data.resources[0]),
        withAsyncData(this.asyncExtensions, (data) =>
          extractExtensionsFromObject(data.resources[0]),
        ),
      )
      .subscribe();
  }

  private searchByApp() {
    this.appService
      .getApp(this.searchForm.controls.searchTerm.value)
      .pipe(
        withAsyncAction(this.asyncAction, 'searching'),
        withAsyncData(this.asyncUserOrApp, (data) => data),
        withAsyncData(this.asyncExtensions, (data) =>
          extractExtensionsFromObject(data),
        ),
        withAsyncDataV2(this.appTrackerService.asyncApp, (data) => data),
      )
      .subscribe({
        error: (error) => {
          if (error.status === 404) noop();
          else throw error;
        },
      });
  }

  private responseToCommonAttributes(user: UserResource) {
    if (!this.formCommonAttributesComponents.first) return;
    const form = this.formCommonAttributesComponents.first.commonAttributesForm;

    form.setValuesAndResetInitial({
      firstName: user.name.givenName,
      lastName: user.name.familyName,
      active: user.active,
      username: user.userName,
    });
    this.changeDetector.detectChanges();
  }

  private responseToAppRelatedAttributes(user: UserResource) {
    const form = this.formGroupAebCustomsComponents.first?.groupAEBCustomsForm;
    const groupAEBCustomsProperties =
      user[USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID];

    if (!form) return;
    if (!groupAEBCustomsProperties) return;

    form.setValuesAndResetInitial({
      Tenant: groupAEBCustomsProperties.Tenant ?? null,
      LocationCode: groupAEBCustomsProperties.LocationCode,
      EnterpriseId: groupAEBCustomsProperties.EnterpriseId ?? null,
      CustomerId: groupAEBCustomsProperties.CustomerId ?? null,
      ContactId: groupAEBCustomsProperties.ContactId ?? null,
      Company: groupAEBCustomsProperties.Company ?? null,
      CsspCustomerIdentificationAttribute:
        groupAEBCustomsProperties.CsspCustomerIdentificationAttribute,
    });
  }

  private trackGetHumanUsersAsync() {
    this.asyncUserOrApp.onChanges$.subscribe(([state, data]) => {
      if (state === 'success-with-data') {
        setTimeout(() => {
          const safeParseUser = UserResourceSchema.safeParse(data);
          if (safeParseUser.success) {
            this.responseToCommonAttributes(safeParseUser.data);
            this.responseToAppRelatedAttributes(safeParseUser.data);
            return;
          }
        });
      }
    });
  }

  private trackFormsChanges() {
    let subscription: Subscription | null = null;

    combineLatest([
      this.formCommonAttributesComponents.changes,
      this.formGroupAebCustomsComponents.changes,
    ])
      .pipe(
        map(
          () =>
            [
              this.formCommonAttributesComponents.first,
              this.formGroupAebCustomsComponents.first,
            ] as const,
        ),
        filter(([component1, component2]) => !!component1 && !!component2),
      )
      .subscribe(
        ([formCommonAttributesComponent, formGroupAebCustomsComponent]) => {
          if (subscription) subscription.unsubscribe();

          subscription = combineLatest([
            formCommonAttributesComponent.commonAttributesForm.hasChanged$,
            formGroupAebCustomsComponent.groupAEBCustomsForm.hasChanged$,
          ]).subscribe(([hasChanged1, hasChanged2]) => {
            this.hasFormChanges.next(hasChanged1 || hasChanged2);
          });
        },
      );
  }

  onUpdate() {
    if (!this.formCommonAttributesComponents.first) return;
    if (!this.formGroupAebCustomsComponents.first) return;

    const commonAttributesData =
      this.formCommonAttributesComponents.first.commonAttributesForm
        .currRawValues;
    const groupAEBCustomsData =
      this.formGroupAebCustomsComponents.first.groupAEBCustomsForm
        .currRawValues;

    const payload = {
      active: commonAttributesData.active ?? undefined,
      userName: commonAttributesData.username ?? undefined,
      addresses: [],
      phoneNumbers: [],
      emails: [
        {
          value: `${commonAttributesData.username}`,
          type: 'default',
        },
      ],
      name: {
        givenName: `${commonAttributesData.firstName}`,
        familyName: `${commonAttributesData.lastName}`,
        formatted: `${commonAttributesData.firstName} ${commonAttributesData.lastName}`,
      },
      schemas: [CORE_USER_ID, USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID],
      [USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID]: {
        // Tenant: groupAEBCustomsData.Tenant ?? undefined,
        LocationCode: groupAEBCustomsData.LocationCode ?? undefined,
        EnterpriseId: groupAEBCustomsData.EnterpriseId ?? undefined,
        CustomerId: groupAEBCustomsData.CustomerId ?? undefined,
        ContactId: groupAEBCustomsData.ContactId ?? undefined,
        Company: groupAEBCustomsData.Company ?? undefined,
        CsspCustomerIdentificationAttribute:
          asCsspCustomerIdentificationAttribute(
            groupAEBCustomsData.CsspCustomerIdentificationAttribute,
          ),
      },
    };

    const id = this.asyncUserOrApp.data$.value?.id || '';

    this.userService
      .updateUser(id, payload)
      .pipe(
        withAsyncAction(this.asyncAction, 'updating'),
        withAsyncData(this.asyncUserOrApp, (data) => data),
      )
      .subscribe({
        next: () => {
          this.toastService.show({
            message: 'Human Account updated successfully',
            type: 'success',
          });
        },
      });
  }

  private keepWhatToShowUpdated() {
    combineLatest([
      this.asyncAction.onChanges$(),
      this.asyncUserOrApp.onChanges$,
    ]).subscribe(([[actionName, actionState], [dataState, dataData]]) => {
      if (
        (actionName === 'searching' &&
          actionState === 'success' &&
          dataState === 'success-with-data') ||
        actionName === 'updating'
      ) {
        this.whatToShow = 'form';
      } else if (
        actionName === 'searching' &&
        dataState === 'not-initialized'
      ) {
        this.whatToShow = 'message-search-something';
      } else if (
        actionName === 'searching' &&
        (dataState === 'success-without-data' || dataState === 'error')
      ) {
        this.whatToShow = 'message-search-not-found';
      }
    });
  }

  private trackSearchByChanges() {
    this.searchForm.valueChanges
      .pipe(
        map((value) => value.searchBy),
        startWith(this.searchForm.value.searchBy),
        distinctUntilChanged((prev, curr) => prev === curr),
      )
      .subscribe((searchBy) => {
        this.asyncUserOrApp.state$.next('not-initialized');
        this.asyncAction._name$.next('searching');
      });
  }

  private SyncQueryParamsAndForm() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let submitSearch = false;
      const searchTerm = params['searchTerm'];
      const searchBy = params['searchBy'];

      if (searchTerm && searchTerm !== this.searchForm.value.searchTerm) {
        this.searchForm.patchValue({ searchTerm }, { emitEvent: false });
        submitSearch = true;
      }
      if (searchBy && searchBy !== this.searchForm.value.searchBy) {
        this.searchForm.patchValue({ searchBy }, { emitEvent: false });
        submitSearch = true;
      }

      if (submitSearch) this.onSubmitSearch();
    });

    this.searchForm.valueChanges.subscribe(({ searchTerm, searchBy }) => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { searchTerm, searchBy },
        queryParamsHandling: 'merge',
      });
    });
  }
}
