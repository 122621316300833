import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'xbp-option',
  standalone: true,
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  imports: [],
})
export class XbpOptionComponent implements OnInit {
  @Input() value?: any;
  @Output() click = new EventEmitter<{
    text: any;
    value: any;
  }>();

  constructor(public elem: ElementRef) {}

  ngOnInit(): void {}

  get innerText(): string {
    return this.elem.nativeElement.innerText;
  }

  onClick(): void {
    this.click.emit({
      text: this.elem.nativeElement.innerText,
      value: this.value,
    });
  }
}
