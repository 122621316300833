<div class="credential-api-products">
  <div class="heading">
    <div class="text-caption">API Products</div>
    <xbp-horizontal-line />
  </div>
  <div class="api-product-list" formArrayName="apiProducts">
    @for (
      apiProductFormGroup of apiProductsFormArray.controls;
      track apiProductFormGroup.value.productName;
      let apiProductFormGroupIndex = $index
    ) {
      <div class="api-product-item" [formGroupName]="apiProductFormGroupIndex">
        <ng-container>
          <ng-container>
            <div class="information">
              <span class="text-body-strong">
                ◼ {{ apiProductFormGroup.value.productName }}
              </span>
              <span class="text-caption"> (Product Name)</span>
            </div>
            <div class="approval-status-container">
              <gls-toggle-switch-multiple formControlName="approvalStatus">
                <ng-template glsTemplate="labelSlot">
                  <div class="text-label">Access Status</div>
                </ng-template>
                <ng-template glsTemplate="optionsSlot">
                  <gls-toggle-switch-multiple-option [value]="'approve'">
                    Approved
                  </gls-toggle-switch-multiple-option>
                  <gls-toggle-switch-multiple-option [value]="'revoke'">
                    Revoked
                  </gls-toggle-switch-multiple-option>
                </ng-template>
              </gls-toggle-switch-multiple>
            </div>
          </ng-container>
        </ng-container>
      </div>
    } @empty {
      <div class="text-caption">No API Products</div>
    }
  </div>
</div>
