@if (asyncApp.data) {
  <ng-container [formGroup]="form">
    <app-form-app-core />
    @for (item of asyncExtensions.data?.entries() ?? []; track item[0]) {
      <br />
      <app-card-extension
        [extensionData]="asyncExtensions.data?.get(item[0]) ?? null"
        [extensionDefinition]="(APP_EXTENSIONS_DEFINITIONS | async)![0]"
      />
    }
    <br />
    <div class="button-container">
      <gls-button
        variant="primary"
        (click)="updateApp()"
        [loading]="actionTrackerService.action.is('updating', 'performing')"
        [disabled]="!form.hasChanged"
      >
        Update
      </gls-button>
    </div>
  </ng-container>
} @else {
  <div>No app has been provided</div>
}
