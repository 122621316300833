import { z } from 'zod';
import { ApiProductSchema } from './api-product.schema';

export const AppCredentialSchema = z.object({
  apiProducts: z.array(ApiProductSchema),
  clientId: z.string(),
  approvalStatus: z.union([
    z.literal('approved'),
    z.literal('revoked'),
    z.literal('pending'),
  ]),
  issuedAt: z.number(),
  expireAt: z.number(),
});

export type AppCredential = z.infer<typeof AppCredentialSchema>;
