import { z } from 'zod';

export const ResourceSchema = z.object({
  id: z.string(),
  externalId: z.string().optional(),
  meta: z.object({
    resourceType: z.string(),
    created: z.union([z.number(), z.string()]),
    lastModified: z.union([z.number(), z.string()]),
    location: z.string(),
  }),
});

export type Resource = z.infer<typeof ResourceSchema>;
