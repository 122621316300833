import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CardComponent,
  GLSTemplateDirective,
  TextInputComponent,
  ToggleSwitchMultipleComponent,
  ToggleSwitchMultipleOptionComponent,
} from '@gls/platform-ui';
import { TrackedForm } from '../../tracked-form';

@Component({
  selector: 'app-form-common-attributes',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    FormsModule,
    GLSTemplateDirective,
    ReactiveFormsModule,
    TextInputComponent,
    ToggleSwitchMultipleComponent,
    ToggleSwitchMultipleOptionComponent,
  ],
  templateUrl: './form-common-attributes.component.html',
  styleUrl: './form-common-attributes.component.scss',
})
export class FormCommonAttributesComponent {
  commonAttributesForm = TrackedForm.from(
    new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      username: new FormControl(''),
      active: new FormControl(true),
    }),
  );
}
