import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { COUNTRIES } from '../../../models/business/others/tenant';
import { CommonModule } from '@angular/common';
import {
  CardComponent,
  CheckboxComponent,
  GLSTemplateDirective,
  TextInputComponent,
} from '@gls/platform-ui';
import { TrackedForm } from '../../tracked-form';
import { XbpSearchableComponent } from '@xbp-commons/ui/dropdown/dropdown-searchable/dropdown-searchable.component';
import { XbpOptionComponent } from '@xbp-commons/ui/dropdown/option/option.component';

@Component({
  selector: 'app-form-group-aeb-customs',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    TextInputComponent,
    GLSTemplateDirective,
    XbpSearchableComponent,
    XbpOptionComponent,
  ],
  templateUrl: './form-group-aeb-customs.component.html',
  styleUrl: './form-group-aeb-customs.component.scss',
})
export class FormGroupAebCustomsComponent {
  groupAEBCustomsForm = TrackedForm.from(
    new FormGroup({
      Tenant: new FormControl({ value: '', disabled: true }),
      LocationCode: new FormControl(''),
      EnterpriseId: new FormControl(''),
      CustomerId: new FormControl(''),
      ContactId: new FormControl(''),
      Company: new FormControl(''),
      CsspCustomerIdentificationAttribute: new FormControl(''),
    }),
  );

  @Input() schemasForms!: FormGroup;
  readonly COUNTRIES = COUNTRIES;
}
