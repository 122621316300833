import { validateSCIMSchema } from '../../refines/validateSCIMSchema';
import { z } from 'zod';

export const AppPutRequestBodySchema = z
  .object({
    displayName: z.string(),
    approvalStatus: z.union([
      z.literal('approved'),
      z.literal('revoked'),
      z.literal('pending'),
    ]),
    credentials: z.array(
      z.object({
        clientId: z.string(),
        approvalStatus: z.union([
          z.literal('approve'),
          z.literal('revoke'),
          z.literal('pending'),
        ]),
        apiProducts: z.array(
          z.object({
            productName: z.string(),
            approvalStatus: z.union([
              z.literal('approve'),
              z.literal('revoke'),
              z.literal('pending'),
            ]),
          }),
        ),
      }),
    ),
    schemas: z.array(z.string()),
  })
  .passthrough()
  .superRefine(validateSCIMSchema);

export type AppPutRequestBody = z.infer<typeof AppPutRequestBodySchema>;
