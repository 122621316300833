<div class="home-container">
  <div *ngIf="isUserLoggedIn">
    <div class="center">
      <h1>
        Welcome to UM2
      </h1>
      <div class="center">
        <button><a routerLink="/human-accounts">Go to Human Accounts</a></button>
      </div>
    </div>
  </div>
  <div *ngIf="!isUserLoggedIn">
    <div class="center">
      <h1>
        You have to login!
      </h1>
    </div>
  </div>
</div>
