import { Injectable } from '@angular/core';
import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import { AsyncDataV2 } from '@xbp-commons/async-data/async-data-v2.class';

@Injectable({
  providedIn: 'root',
})
export class AppTrackerService {
  asyncApp = new AsyncDataV2<AppGetRequestResp>();
}
