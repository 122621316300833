import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Factory function for a generic validator that checks if the control's value matches a forbidden value.
 * @param forbiddenValue The value that should not be allowed
 * @returns A validator function
 */
export function forbiddenValueValidator(forbiddenValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === forbiddenValue) {
      return { forbiddenValue: { value: control.value } }; // <- error returned if value matches the forbidden value
    }
    return null; // <- no error
  };
}
