import { ResourceSchema } from '@models/http/generics/resource.schema';
import { z } from 'zod';
import { UserSchema } from '../user.schema';
import { PageSchema } from '@models/http/generics/page.schema';

export const UserResourceSchema = ResourceSchema.merge(UserSchema).extend({
  password: z.string().optional(),
});
export type UserResource = z.infer<typeof UserResourceSchema>;

export const UserGetRequestRespSchema = PageSchema(UserResourceSchema);
export type UserGetRequestResp = z.infer<typeof UserGetRequestRespSchema>;
