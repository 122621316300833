import { AppSchema } from '../app.schema';
import { validateSCIMSchema } from '../../refines/validateSCIMSchema';
import { z } from 'zod';

export const AppPutRequestRespSchema = AppSchema.innerType()
  .extend({
    meta: z.object({
      created: z.number(),
      createdBy: z.string(),
      lastModified: z.number(),
      location: z.string(),
      resourceType: z.literal('App'),
    }),
  })
  .passthrough()
  .superRefine(validateSCIMSchema);

export type AppPutRequestResp = z.infer<typeof AppPutRequestRespSchema>;
