<gls-modal (hideModal)="close()">
  <ng-template glsTemplate="header">
    <div class="d-flex jc-space-between">
      <span>Server Error</span>
    </div>
  </ng-template>
  <ng-template glsTemplate="main">
    <gls-alert color="error" variant="standard" [closeable]="false">
      <span alert-text>
        <span class="text-subheading">{{ error | errorHeading }}</span>
        <p class="text-body">{{ error | errorDetails }}</p>
      </span>
    </gls-alert>
  </ng-template>
  <ng-template glsTemplate="actions">
    <div
      class="d-flex"
      style="justify-content: end; gap: 1rem; flex-wrap: wrap"
    >
      <gls-button
        [disabled]="false"
        [destructive]="false"
        [loading]="false"
        variant="secondary"
        (click)="close()"
      >
        Close
      </gls-button>
      @if (error.status === 0) {
        <gls-button (click)="logout()" variant="primary-exceptional">
          Logout
        </gls-button>
      }
    </div>
  </ng-template>
</gls-modal>
