import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent {
  @Input() dropdownItems:any = [];
  @Output() selectedOption: any = new EventEmitter<string>();

  onSelect(event: Event) {
    this.selectedOption.emit((event.target as HTMLTextAreaElement).value);
  }
}
