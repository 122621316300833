<gls-navigation aside-content>
  <gls-navigation-header header></gls-navigation-header>

  <gls-navigation-content content>
    <gls-navigation-menu-list [showItem]="true">
      <ng-template glsTemplate="label">
        <span>User Management</span>
      </ng-template>
      <ng-template glsTemplate="item">
        <gls-navigation-menu-item
          [type]="'primary'"
          [selected]="isSelected('/accounts/search')"
          routerLink="/accounts/search"
        >
          <gls-icon icon [iconClass]="'icon-search'"></gls-icon>
          <a label>Search</a>
        </gls-navigation-menu-item>
        <gls-navigation-menu-item
          [type]="'primary'"
          [selected]="isSelected('/accounts/create-human-account')"
          routerLink="/accounts/create-human-account"
        >
          <gls-icon icon [iconClass]="'icon-plus'"></gls-icon>
          <a label>Create Human Account</a>
        </gls-navigation-menu-item>
      </ng-template>
    </gls-navigation-menu-list>
  </gls-navigation-content>

  <gls-navigation-footer footer>
    <gls-avatar [size]="'small'" [backgroundColor]="'green'" avatar>
      <span initials
        >{{ (profile$ | async)?.givenName?.charAt(0)
        }}{{ (profile$ | async)?.surname?.charAt(0) }}</span
      >
      <img
        *ngIf="profileImage$ | async"
        [src]="profileImage$ | async"
        alt="profile picture"
        image
      />
    </gls-avatar>
    <span username
      >{{ (profile$ | async)?.givenName }}
      {{ (profile$ | async)?.surname }}</span
    >
    <span workplace>{{ (profile$ | async)?.jobTitle }}</span>
    <div menu>
      <gls-icon-button
        icon="icon-navigation-menu-horizontal"
        [inverse]="true"
        [glsPopper]="{
          targetComponent: groupSubItem,
          mode: 'click',
          placement: 'right',
        }"
      ></gls-icon-button>
      <gls-dropdown #groupSubItem>
        <gls-dropdown-item-label [label]="'Actions'"></gls-dropdown-item-label>
        <gls-dropdown-item
          label="Logout"
          (click)="logout()"
        ></gls-dropdown-item>
      </gls-dropdown>
    </div>
  </gls-navigation-footer>
</gls-navigation>
