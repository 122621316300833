import { z, ZodSchema, ZodType } from 'zod';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export type ZodToAngularForm<T extends ZodSchema> =
  T extends z.ZodObject<infer Shape>
    ? FormGroup<{
        [K in keyof Shape]: ZodToAngularForm<Shape[K]>;
      }>
    : T extends z.ZodArray<infer Item>
      ? FormArray<ZodToAngularForm<Item>>
      : T extends z.ZodNullable<infer Inner>
        ? ZodToAngularForm<Inner>
        : T extends z.ZodOptional<infer Inner>
          ? ZodToAngularForm<Inner>
          : T extends z.ZodEffects<infer Inner>
            ? ZodToAngularForm<Inner>
            : FormControl;

/**
 * Recursively converts a Zod schema to an Angular AbstractControl (FormGroup, FormArray, or FormControl).
 */
export function schemaToFormGroup<T extends ZodSchema>(
  schema: T,
): ZodToAngularForm<T> {
  if (isZodObject(schema)) {
    const groupControls = Object.fromEntries(
      Object.entries(schema.shape).map(([key, subSchema]) => [
        key,
        schemaToFormGroup(subSchema as ZodType),
      ]),
    );

    return new FormGroup(groupControls) as ZodToAngularForm<T>;
  }

  if (isZodArray(schema)) {
    // Create FormArray with explicit item type
    const arrayItemSchema = schema.element; // Get the schema of array items
    return new FormArray<ZodToAngularForm<typeof arrayItemSchema>>(
      [], // Initialize the array with no elements
    ) as ZodToAngularForm<T>;
  }

  if (isZodPrimitive(schema)) {
    const validators = zodToAngularValidators(schema);
    return new FormControl('', validators) as ZodToAngularForm<T>;
  }

  if (isZodOptionalOrNullable(schema)) {
    return new FormControl(null) as ZodToAngularForm<T>;
  }

  if (isZodEffects(schema)) {
    const innerSchema = schema._def.schema; // Unwrap the inner schema
    const innerForm = schemaToFormGroup(innerSchema); // Recursively create the form group

    if (innerForm instanceof FormGroup || innerForm instanceof FormArray) {
      innerForm.addValidators(zodToAngularValidators(schema)); // Add the refinement as a validator
      return innerForm as ZodToAngularForm<T>;
    }

    // Apply the refinement if the inner form is a FormControl
    const control = new FormControl(
      '',
      zodToAngularValidators(schema),
    ) as ZodToAngularForm<T>;
    return control;
  }

  throw new Error(`Unsupported Zod schema type: ${schema}`);
}

function zodToAngularValidators(schema: ZodType): ValidatorFn[] {
  const validators: ValidatorFn[] = [];

  if (!isZodOptionalOrNullable(schema)) {
    validators.push(Validators.required);
  }

  if (schema instanceof z.ZodString) {
    const checks = schema._def.checks || [];
    for (const check of checks) {
      if (check.kind === 'min') {
        validators.push(Validators.minLength(check.value));
      }
      if (check.kind === 'max') {
        validators.push(Validators.maxLength(check.value));
      }
      if (check.kind === 'email') {
        validators.push(Validators.email);
      }
      if (check.kind === 'regex') {
        validators.push(Validators.pattern(check.regex));
      }
    }
  }

  if (schema instanceof z.ZodNumber) {
    const checks = schema._def.checks || [];
    for (const check of checks) {
      if (check.kind === 'min') {
        validators.push(Validators.min(check.value));
      }
      if (check.kind === 'max') {
        validators.push(Validators.max(check.value));
      }
    }
  }

  if (schema instanceof z.ZodEffects) {
    validators.push((control: AbstractControl): ValidationErrors | null => {
      const result = schema.safeParse(control.value);

      if (result.success) return null;

      const messages = result.error.errors.map((err) => err.message);
      return { customRefinement: messages.join(', ') };
    });
  }

  return validators;
}

function isZodObject(schema: ZodType): schema is z.ZodObject<any> {
  return schema instanceof z.ZodObject;
}

function isZodArray(schema: ZodType): schema is z.ZodArray<any> {
  return schema instanceof z.ZodArray;
}

function isZodPrimitive(
  schema: ZodType,
): schema is z.ZodString | z.ZodNumber | z.ZodBoolean | z.ZodDate {
  return (
    schema instanceof z.ZodString ||
    schema instanceof z.ZodNumber ||
    schema instanceof z.ZodBoolean ||
    schema instanceof z.ZodDate
  );
}

function isZodOptionalOrNullable(
  schema: ZodType,
): schema is z.ZodOptional<any> | z.ZodNullable<any> {
  return schema instanceof z.ZodOptional || schema instanceof z.ZodNullable;
}

function isZodEffects(schema: ZodType): schema is z.ZodEffects<any> {
  return schema instanceof z.ZodEffects;
}
