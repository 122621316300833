import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppLayoutComponent as GLSAppLayoutComponent,
  LayoutHeaderContentDirective,
} from '@gls/platform-ui';
import { PanelLeftComponent } from './components/panel-left/panel-left.component';
import { PanelTopComponent } from './components/panel-top/panel-top.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    GLSAppLayoutComponent,
    PanelLeftComponent,
    PanelTopComponent,
    LayoutHeaderContentDirective,
  ],
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {}
