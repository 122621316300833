import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import { CommonModule } from '@angular/common';
import { XbpOptionComponent } from '@xbp-commons/ui/dropdown/option/option.component';
import { IconComponent } from '@gls/platform-ui';

@Component({
  selector: 'xbp-dropdown-searchable',
  standalone: true,
  templateUrl: './dropdown-searchable.component.html',
  styleUrls: ['./dropdown-searchable.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XbpSearchableComponent),
      multi: true,
    },
  ],
  animations: [],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, IconComponent],
})
export class XbpSearchableComponent
  implements
    OnInit,
    AfterViewInit,
    AfterContentInit,
    ControlValueAccessor,
    OnDestroy
{
  value?: any;
  displayText?: string;
  options$: Array<Subscription> = [];

  searchTerm$ = new BehaviorSubject<string>('');

  @Input() disabled: boolean = false;
  @Input() placeholder?: string;
  @Input() state$ = new BehaviorSubject<'opened' | 'closed'>('closed');
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() updateDisplayTextWhenOptionsChanged: boolean = true;

  @Output() searchTermChange = new EventEmitter();

  @ContentChildren(XbpOptionComponent)
  optionComponents!: QueryList<XbpOptionComponent>;

  afterContentViewInitialised$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.listenToState();
    this.listenToSearchTerm();

    this.listenToOptionClicks(); // we have to call this once to setup initial options (if there are any)
    this.listenToOptionListChanges();
  }

  ngAfterContentInit(): void {
    this.afterContentViewInitialised$.next(true);
  }

  onSearch(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  listenToOptionListChanges() {
    this.optionComponents.changes.subscribe((changes) => {
      this.options$.forEach((subscription) => subscription.unsubscribe());
      this.listenToOptionClicks();
      this.updateDisplayTextWhenOptionsChanged
        ? this.updateDisplayText()
        : null;
    });
  }

  listenToOptionClicks() {
    this.optionComponents.forEach((option) => {
      const subscription = option.click.subscribe((data) => {
        this.onChange(data.value);
        this.onTouch();
        this.value = data.value;
        this.displayText = data.text;
        this.searchTerm$.next('');
      });
      this.options$.push(subscription);
    });
  }

  private listenToState(): void {
    this.state$
      .pipe
      // untilDestroyed(this)
      ()
      .subscribe((state) => {
        if (state === 'closed') this.searchTerm$.next('');
      });
  }

  private listenToSearchTerm(): void {
    this.searchTerm$
      // .pipe(untilDestroyed(this))
      .subscribe((searchTerm) => {
        this.searchTermChange.emit(searchTerm);
        if (this.optionComponents) {
          const lowerCaseSearchTerm = searchTerm.toLowerCase();

          this.optionComponents.forEach((optionComponent) => {
            const optionText = optionComponent.innerText.toLowerCase();

            // Check if the option text matches the search term
            const isMatch = optionText.includes(lowerCaseSearchTerm);

            // Show or hide the option based on the match
            const optionElement = optionComponent.elem
              .nativeElement as HTMLElement;
            optionElement.style.display = isMatch ? 'block' : 'none';
          });
        }
      });
  }

  toggleState(): void {
    if (this.disabled) return;
    this.state$.value === 'opened' ? this.closeDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    if (this.disabled) return;
    this.state$.next('opened');
  }

  closeDropdown(): void {
    this.state$.next('closed');
  }

  onChange: any = (value: boolean) => {};

  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.afterContentViewInitialised$
      .pipe(
        // untilDestroyed(this),
        filter((x) => x),
        take(1),
      )
      .subscribe((x) => this.updateDisplayText());
  }

  private updateDisplayText() {
    this.displayText = this.optionComponents.find(
      (option) => option.value === this.value,
    )?.innerText;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy() {
    this.options$.forEach((subscription) => subscription.unsubscribe());
  }
}
