import { Component } from '@angular/core';

@Component({
  selector: 'xbp-horizontal-line',
  standalone: true,
  imports: [],
  templateUrl: './xbp-horizontal-line.component.html',
  styleUrl: './xbp-horizontal-line.component.scss',
})
export class XbpHorizontalLineComponent {}
