import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionDefinitionResource',
  standalone: true,
})
export class ExtensionDefinitionResourcePipe implements PipeTransform {
  transform(value: string): string {
    const resource = value.split(':')[8] ?? 'Invalid Extension Definition';

    if (resource === 'User') return 'Human Account';
    if (resource === 'App') return 'Technical Account';
    return resource;
  }
}
