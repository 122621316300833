<gls-modal (hideModal)="close()">
  <ng-template glsTemplate="header">
    <div class="d-flex jc-space-between">
      <span>Add API Product</span>
    </div>
  </ng-template>
  <ng-template glsTemplate="main" [formGroup]="apiProductFormGroup">
    <gls-combobox
      formControlName="productName"
      placeholder="Select an API Product"
      [loading]="false"
      [multiple]="false"
    >
      <ng-template glsTemplate="labelSlot">API Product</ng-template>
      <gls-combobox-option
        [ngValue]="'customs-consignments-v3'"
        [label]="'customs-consignments-v3'"
      ></gls-combobox-option>
      <ng-template glsTemplate="errorSlot">
        <gls-field-error
          *ngIf="
            apiProductFormGroup.controls['productName'].hasError(
              'forbiddenValues'
            ) && apiProductFormGroup.controls.productName.touched
          "
        >
          <span
            >The API Product "{{
              apiProductFormGroup.controls["productName"].getError(
                "forbiddenValues"
              ).value
            }}" is already in this credential.
          </span>
        </gls-field-error>
      </ng-template>
    </gls-combobox>
    <gls-toggle-switch-multiple formControlName="approvalStatus">
      <ng-template glsTemplate="labelSlot">
        <div class="text-label">Access Status</div>
      </ng-template>
      <ng-template glsTemplate="optionsSlot">
        <gls-toggle-switch-multiple-option [value]="'approve'">
          Approved
        </gls-toggle-switch-multiple-option>
        <gls-toggle-switch-multiple-option [value]="'revoke'">
          Revoked
        </gls-toggle-switch-multiple-option>
      </ng-template>
    </gls-toggle-switch-multiple>
  </ng-template>
  <ng-template glsTemplate="actions">
    <gls-button (click)="close()" variant="secondary"> Discard </gls-button>
    <gls-button (click)="submit()" variant="primary-exceptional">
      Add
    </gls-button>
  </ng-template>
</gls-modal>
