import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent {
  @Input() dropdownItems:any = [];
  @Output() selectedOption: any = new EventEmitter<string>();

  onSelect(event: Event) {
    this.selectedOption.emit((event.target as HTMLTextAreaElement).value);
  }
}
