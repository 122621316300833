<div class="text-label">
  <ng-content select="[label]"></ng-content>
</div>
<div
  class="dropdown-container text-body"
  [class.opened]="(state$ | async) === 'opened'"
  [class.disabled]="disabled"
  [ngClass]="[size]"
  (click)="toggleState()"
>
  <!--  stableClickOutside
  (clickOutside)="state$.next('closed')"-->
  <input
    type="text"
    [ngModel]="searchTerm$ | async"
    (ngModelChange)="onSearch($event)"
    [placeholder]="displayText || value || placeholder || ''"
    [class.dark-text]="!!value"
    [class.disabled]="disabled"
    [disabled]="disabled"
  />

  <div class="icon" [ngClass]="[size]" [class.disabled]="disabled">
    <gls-icon
      *ngIf="(state$ | async) === 'opened'"
      [iconClass]="'icon-disclosure'"
      [size]="'sm'"
      style="transform: rotate(180deg)"
    ></gls-icon>
    <gls-icon
      *ngIf="(state$ | async) === 'closed'"
      [iconClass]="'icon-disclosure'"
      [size]="'sm'"
    ></gls-icon>
  </div>

  <div class="options" *ngIf="(state$ | async) === 'opened'">
    <ng-content></ng-content>
  </div>
</div>
