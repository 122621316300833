import { Injectable } from '@angular/core';
import { AsyncActions } from '../../pages/internal/human-accounts/accounts-search/accounts-search.page';
import { AsyncAction } from '@shared/rxjs-operators/with-async-action';

@Injectable({
  providedIn: 'root',
})
export class ActionTrackerService {
  public action = new AsyncAction<AsyncActions>('searching');

  constructor() {}
}
