import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  ApiProductForm,
  ApiProductFormValueSchema,
} from '@models/forms/api-product-form.schema';
import { CredentialFormGroup } from '@models/forms/credential-form-group.schema';
import { isNullOrUndefined } from '@shared/xbp-commons';

export const appGetRequestRespTOApiProductsFormArray = (
  app: AppGetRequestResp,
  credentialFormGroup: CredentialFormGroup,
  apiProductsFormArray: FormArray<ApiProductForm>,
) => {
  apiProductsFormArray.clear();

  const clientId = credentialFormGroup.value.clientId;
  const credential = app.credentials.find((c) => c.clientId === clientId);
  if (isNullOrUndefined(credential)) return;

  credential.apiProducts.forEach((apiProduct) => {
    const apiProductFormGroup: ApiProductForm = new FormGroup({
      approvalStatus: new FormControl(translate(apiProduct.approvalStatus)),
      productName: new FormControl(apiProduct.productName),
    });

    const safeParsed = ApiProductFormValueSchema.safeParse(
      apiProductFormGroup.value,
    );
    if (safeParsed.success) {
      apiProductsFormArray.push(apiProductFormGroup);
    } else throw safeParsed.error;
  });
};

const translate = (approvalStatusPassSentence: string) => {
  if (approvalStatusPassSentence === 'approved') return 'approve';
  if (approvalStatusPassSentence === 'revoked') return 'revoke';
  return 'pending';
};
