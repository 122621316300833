import { User, UserSchema } from './user.schema';
import {
  CORE_USER_ID,
  USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
  USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID,
} from '../scim-schemas/scim-schema-id';

export const validateCoreSCIMSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    Array.isArray((data as { schemas?: unknown }).schemas) &&
    (data as { schemas: string[] }).schemas.includes(CORE_USER_ID)
  );
};

export const messageCoreSCIMSchema = {
  message: `The 'schemas' array must always contain '${CORE_USER_ID}'.`,
};

export const validateWelcomeEmailSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    (!(data as Record<string, unknown>)[
      USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID
    ] ||
      (Array.isArray((data as { schemas?: unknown }).schemas) &&
        (data as { schemas: string[] }).schemas.includes(
          USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID,
        )))
  );
};

export const messageWelcomeEmailSchema = {
  message: `The property ${USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID} must only exist if it is listed in the schemas array.`,
};

export const validateGroupAEBCustomsSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    (!(data as Record<string, unknown>)[
      USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID
    ] ||
      (Array.isArray((data as { schemas?: unknown }).schemas) &&
        (data as { schemas: string[] }).schemas.includes(
          USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
        )))
  );
};

export const messageGroupAEBCustomsSchema = {
  message: `The property ${USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID} must only exist if it is listed in the schemas array.`,
};

export function isHumanUserType(data: unknown): data is User {
  const parsed = UserSchema.safeParse(data);
  return parsed.success;
}
