import { z } from 'zod';
import { AppSchema } from '../app.schema';
import { validateSCIMSchema } from '@models/business/refines/validateSCIMSchema';

export const AppGetRequestRespSchema = AppSchema.innerType()
  .extend({
    meta: z.object({
      created: z.number(),
      createdBy: z.string(),
      lastModified: z.number(),
      location: z.string(),
      resourceType: z.literal('App'),
    }),
  })
  .passthrough()
  .superRefine(validateSCIMSchema);

export type AppGetRequestResp = z.infer<typeof AppGetRequestRespSchema>;
