import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Factory function for a generic validator that checks if the control's value matches any forbidden value.
 * @param forbiddenValues An array of values that should not be allowed
 * @returns A validator function
 */
export function forbiddenValuesValidator(
  forbiddenValues: string[],
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (forbiddenValues.includes(control.value)) {
      return {
        forbiddenValues: { value: control.value, forbidden: forbiddenValues },
      }; // <- error returned if value matches any forbidden value
    }
    return null; // <- no error
  };
}
