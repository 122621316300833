<header class="header">
  <h1 class="header__title">
    <div class="titel-contaiter">
      <a routerLink="/home">
        <img src="assets/images/gls-group-logo.svg" alt="" width="110" style="margin-right: 50px;">
      </a>
      <a href="/" class="title" style="padding-top: 50px;">User Management 2</a>
    </div>
  </h1>
  <div class="menu-items">
    <button class="header-login-logout-button" *ngIf="!isUserLoggedIn" (click)="login()">
      <img src="assets/images/gls-group-Icon-login-logout-48x48-yellow.svg" alt="" width="49" height="25">
      Login
    </button>
    <button class="header-login-logout-button" *ngIf="isUserLoggedIn" (click)="logout()">
      <img src="assets/images/gls-group-Icon-login-logout-48x48-yellow.svg" alt="" width="49" height="25">
      Logout
    </button>
  </div>
</header>


