<gls-card [interactive]="false">
  <ng-template glsTemplate="header">
    <div class="card-header" [formGroup]="schemasForms">
      <h4 class="card-header-title">
        <span>Group AEB Customs</span>
        <span class="text-caption text-color-subdued"> (Human Account) </span>
      </h4>
      <gls-checkbox formControlName="groupAEBCustoms" [disabled]="true">
      </gls-checkbox>
    </div>
  </ng-template>
  <ng-template glsTemplate="content">
    <div
      class="form-container"
      [formGroup]="groupAEBCustomsForm"
      *ngIf="schemasForms.controls['groupAEBCustoms'].value"
    >
      <div class="right">
        <div class="two-columns">
          <xbp-dropdown-searchable
            formControlName="Tenant"
            placeholder="Select Tenant"
            [disabled]="true"
          >
            <span label>Tenant</span>
            @for (country of COUNTRIES; track country.code) {
              <xbp-option [value]="country.code">
                {{ country.code + " - " + country.label }}
              </xbp-option>
            }
          </xbp-dropdown-searchable>
          <gls-text-input formControlName="LocationCode" autocomplete="off">
            <ng-template glsTemplate="labelSlot"> Location Code </ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-text-input formControlName="EnterpriseId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Enterprise Id</ng-template>
          </gls-text-input>
          <gls-text-input formControlName="CustomerId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Customer Id</ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-text-input formControlName="ContactId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Contact Id</ng-template>
          </gls-text-input>
          <gls-text-input formControlName="Company" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Company</ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <xbp-dropdown-searchable
            formControlName="CsspCustomerIdentificationAttribute"
            placeholder="Select an option"
            [disabled]="true"
          >
            <span label>Identification Type</span>
            <xbp-option [value]="'contactId'">Contact Id</xbp-option>
            <xbp-option [value]="'customerId'">Customer Id</xbp-option>
            <xbp-option [value]="'enterpriseId'">Enterprise Id</xbp-option>
          </xbp-dropdown-searchable>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
</gls-card>
