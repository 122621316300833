import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  CardComponent,
  GLSTemplateDirective,
  TextInputComponent,
} from '@gls/platform-ui';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { FormCredentialComponent } from './form-credential/form-credential.component';
import { FormMetaComponent } from './form-meta/form-meta.component';
import { appGetRequestRespTOAppCoreForm } from './utils/app-get-request-resp-TO-app-core-form';
import { AppCoreForm } from '@models/forms/app-core-form.schema';

@Component({
  selector: 'app-form-app-core',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    FormsModule,
    GLSTemplateDirective,
    ReactiveFormsModule,
    TextInputComponent,
    FormCredentialComponent,
    FormMetaComponent,
  ],
  templateUrl: './form-app-core.component.html',
  styleUrl: './form-app-core.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class FormAppCoreComponent implements OnInit {
  private formGroupDirective = inject(FormGroupDirective);
  private appTrackerService = inject(AppTrackerService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  protected asyncApp = this.appTrackerService.asyncApp;

  ngOnInit(): void {
    this.asyncApp.successfulData$.subscribe((data) => {
      appGetRequestRespTOAppCoreForm(data, this.parentFormGroup);
      this.changeDetectorRef.detectChanges();
    });
  }

  get parentFormGroup() {
    return this.formGroupDirective.form as AppCoreForm;
  }
}
