import { z } from 'zod';

export const ApiProductSchema = z.object({
  productName: z.string(),
  approvalStatus: z.union([
    z.literal('approved'),
    z.literal('revoked'),
    z.literal('pending'),
  ]),
});

export type ApiProduct = z.infer<typeof ApiProductSchema>;
