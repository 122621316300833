import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-related-attributes',
  templateUrl: './related-attributes.component.html',
  styleUrls: ['./related-attributes.component.scss']
})
export class RelatedAttributesComponent {
  @Input() attributes: any;
}
