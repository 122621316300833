import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, switchMap, throwError, timer } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { authConfig } from '../../auth-config';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.baseUrl)) {
      return next.handle(req);
    }

    if (this.msalService.instance.getAllAccounts().length === 0) {
      return next.handle(req);
    }

    const idToken = this.msalService.instance.getAllAccounts()[0].idToken;
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${idToken}`),
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // If the response indicates an unauthorized error (401), refresh the token
        if (error.status === 401 || error.status === 0) {
          return from(
            this.msalService.instance.acquireTokenSilent({
              account: this.msalService.instance.getAllAccounts()[0],
              forceRefresh: true, // Ensure a fresh token is retrieved
              scopes: authConfig.scopes, // Replace with your auth scopes
            }),
          ).pipe(
            switchMap((response: AuthenticationResult) => {
              // Clone the request with the new token and retry
              const newAuthReq = req.clone({
                headers: req.headers.set(
                  'Authorization',
                  `Bearer ${response.idToken}`,
                ),
              });

              return timer(500).pipe(switchMap(() => next.handle(newAuthReq)));
            }),
            catchError(() => {
              // If token acquisition still fails, propagate the error
              return throwError(() => error);
            }),
          );
        }

        // If the error is not related to unauthorized access, propagate the error as-is
        return throwError(() => error);
      }),
    );
  }
}
