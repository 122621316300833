import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, fromUnixTime, parseISO } from 'date-fns';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
  transform(value: number | string | null | undefined): string {
    if (!value) return '';
    if (value === -1) return 'N/A';

    let date: Date;

    if (typeof value === 'number') {
      try {
        // If value is in seconds (Unix timestamp)
        if (value.toString().length === 10) {
          date = fromUnixTime(value);
        } else {
          // Otherwise, consider it as milliseconds
          date = new Date(value);
        }
      } catch (error) {
        return 'Invalid number value';
      }
    } else {
      // value is a string here so handle string as ISO date
      date = parseISO(value);
    }

    try {
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return 'Invalid time value';
    }
  }
}
