import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RelativeTimePipe } from '@shared/pipes/relative-time.pipe';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TooltipDirective } from '@gls/platform-ui';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { XbpHorizontalLineComponent } from '@xbp-commons/components/xbp-horizontal-line/xbp-horizontal-line.component';

@Component({
  selector: 'app-form-meta',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RelativeTimePipe,
    DatePipe,
    TooltipDirective,
    AsyncPipe,
    XbpHorizontalLineComponent,
  ],
  templateUrl: './form-meta.component.html',
  styleUrl: './form-meta.component.scss',
})
export class FormMetaComponent {
  private appTrackerService = inject(AppTrackerService);

  protected app$ = this.appTrackerService.asyncApp.successfulData$;
}
