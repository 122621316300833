import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import { AppCoreForm } from '@models/forms/app-core-form.schema';

export const appGetRequestRespTOAppCoreForm = (
  app: AppGetRequestResp,
  form: AppCoreForm,
) => {
  form.patchValuesAndResetTracking({
    id: app.id,
    displayName: app.displayName,
    schemas: app.schemas,
  });
};
