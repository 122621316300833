import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import applicationsAndRoles from '../../../assets/files/applicationsAndRoles.json';
import { GetHumanUsersService } from 'src/app/services/get-human-users.service';

@Component({
  selector: 'app-human-users-table',
  templateUrl: './human-users-table.component.html',
  styleUrls: ['./human-users-table.component.scss']
})
export class HumanUsersTableComponent implements OnInit {

  constructor (private getHumanUsersService: GetHumanUsersService ) {}

  ngOnInit() {
    this.getApps();
  }

  @ViewChild('humanUsersTableContainer', {static: false}) humanUsersTableContainer: ElementRef;

  applications: any = [];
  selectedApp: string = '';
  rolesShownForSelectedApp: string[] = [];
  selectedRoles: any;
  selectedAppRoles: any[];
  selectedAppRolesLength: number;
  RelatedAttributesForSeledtedAppAndSelectedRoles: any[] = [];
  saveButtonActive: boolean = false;
  isAccountClicked: boolean = false;
  rowIsSelected: boolean = false;
  basicAttributes :any;
  applicationsAndRoles = applicationsAndRoles;
  humanUserSearchInput: string = '';
  standardPrefixForhumanUserSearchInput: string = 'userName eq ';
  searchedUserData: any;
  searchedUserDataArray: any = {};
  userName: string;
  firstName: string;
  lastName: string;

  getApps() {
    this.applications = [];
    for(let i=0; i < applicationsAndRoles.data.length; i++) {
      this.applications.push(applicationsAndRoles.data[i]['name']);
    }
  }

  getRoles(appName: string) {
    let roles: string[] = [];
    let selectedApp: any = applicationsAndRoles.data.filter((item: any) => item['name'] === appName);
    for (let i=0; i < selectedApp[0]['roles'].length; i++) {
      roles.push(selectedApp[0]['roles'][i]['name'])
    }
    this.rolesShownForSelectedApp = roles;
  }

  getRoleAttributes(appName: string , ...appRoles: any[]) {
    this.selectedAppRoles = appRoles[0];
    this.selectedAppRolesLength = this.selectedAppRoles.length;
    let allAttributesForSelectedRoles: any[] = [];
    this.RelatedAttributesForSeledtedAppAndSelectedRoles = []
    let allAttributes: any = [];
    let selectedApp: any = applicationsAndRoles.data.filter((item: any) => item['name'] === appName);
    for (let j=0; j < this.selectedAppRoles.length; j++) {
      var attributesForselectedRoles: any = selectedApp[0]['roles'].filter((item: any) => item['name'] === this.selectedAppRoles[j]);
      allAttributesForSelectedRoles.push(attributesForselectedRoles[0]['fields']['attributes'])
    }
    for (let i=0; i<allAttributesForSelectedRoles.length; i++) {
      for (let k=0; k<allAttributesForSelectedRoles[i].length; k++) {
        allAttributes.push(allAttributesForSelectedRoles[i][k]);
      }
    }
    this.RelatedAttributesForSeledtedAppAndSelectedRoles = allAttributes;
  }

  showBasicAttributes() {
    this.isAccountClicked = true;

    let newValues = {
      active: this.searchedUserDataArray['active'],
      userName: this.searchedUserDataArray['userName'],
      emails : this.searchedUserDataArray['email'],
      givenName : this.searchedUserDataArray['firstName'],
      familyName: this.searchedUserDataArray['lastName'],
      formattedName: this.searchedUserDataArray['formatted'],
    };
    this.basicAttributes= {};
    this.basicAttributes = newValues;
  }

  onAppSelected(app: string) {
    this.selectedApp = app;
    this.getRoles(this.selectedApp);
  }

  onRoleSelected(role: string) {
    this.saveButtonActive = true;
    this.selectedRoles = role;
    this.getRoleAttributes(this.selectedApp, this.selectedRoles);
  }

  @ViewChild('saveButton') saveButton: ElementRef;

  scrollToBottom() {
    this.saveButton.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onSearchHumanUserClick() {
    this.searchedUserDataArray = {};
    this.getHumanUsersService.getUserData(this.standardPrefixForhumanUserSearchInput + '\"' + this.humanUserSearchInput + '\"').subscribe(
      (response) => {
        this.searchedUserData = response['resources'][0];
        this.searchedUserDataArray['userName'] = this.searchedUserData['userName'];
        this.searchedUserDataArray['firstName'] = this.searchedUserData['name']['givenName'];
        this.searchedUserDataArray['lastName'] = this.searchedUserData['name']['familyName'];
        this.searchedUserDataArray['formatted'] = this.searchedUserData['name']['formatted'];
        this.searchedUserDataArray['email'] = this.searchedUserData['emails'][0]['value'];
        this.searchedUserDataArray['active'] = this.searchedUserData['active'];
        this.searchedUserDataArray['type'] = this.searchedUserData['meta']['resourceType'];
        this.searchedUserDataArray['address'] = this.searchedUserData['addresses'][0];
      }, error => {
        console.log(error);
        alert("No Results Found!");
      });
  }
}

