import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import applicationsAndRoles from '../../../assets/files/applicationsAndRoles.json';
import { GetHumanUsersService } from 'src/app/services/get-human-users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CorePlatformUiFilterFormControl } from '@gls/platform-ui';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-human-users-table',
  templateUrl: './human-users-table.component.html',
  styleUrls: ['./human-users-table.component.scss'],
})
export class HumanUsersTableComponent implements OnInit {
  public formGroup: FormGroup;
  public formResults: FormGroup;
  public formAppRelatedAttributes: FormGroup;

  public optionsSingle: string[] = [
    'Portugal',
    'France',
    'Belarus',
    'Belgium',
    'China',
  ];
  public optionsMultiple: string[] = [
    'Portugal',
    'France',
    'Belarus',
    'Belgium',
    'China',
    'Pakistan',
    'United States',
    'Germany',
    'Brazil',
    'Canada',
    'Australia',
    'India',
    'Italy',
    'Japan',
    'Mexico',
    'Netherlands',
    'Russia',
    'South Africa',
    'Spain',
    'United Kingdom',
  ];

  state: 'nothing-searched' | 'not-found' | 'found' = 'nothing-searched';
  errorMessage = '';

  public resetSearchInput(key: string) {
    this.formGroup.get(key)?.reset();
  }

  onSubmit() {}

  constructor(
    private getHumanUsersService: GetHumanUsersService,
    private readonly fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.formGroup = this.fb.group({
      searchTerm: new CorePlatformUiFilterFormControl('Search Term', ''),
      searchBy: new CorePlatformUiFilterFormControl(
        'Search By',
        'human-account',
      ),
    });

    this.formResults = this.fb.group({
      firstname: [''],
      lastname: [''],
      email: [{ value: true, disabled: true }],
      status: ['active'],
    });

    this.initFormAppRelatedAttributes();
  }

  ngOnInit() {
    this.getApps();
  }

  @ViewChild('humanUsersTableContainer', { static: false })
  humanUsersTableContainer: ElementRef;

  applications: any = [];
  selectedApp: string = '';
  rolesShownForSelectedApp: string[] = [];
  selectedRoles: any;
  selectedAppRoles: any[];
  selectedAppRolesLength: number;
  RelatedAttributesForSeledtedAppAndSelectedRoles: any[] = [];
  saveButtonActive: boolean = false;
  isAccountClicked: boolean = false;
  rowIsSelected: boolean = false;
  basicAttributes: any;
  applicationsAndRoles = applicationsAndRoles;
  humanUserSearchInput: string = '';
  standardPrefixForhumanUserSearchInput: string = 'userName eq ';
  searchedUserDataArray: any = {};
  userName: string;
  firstName: string;
  lastName: string;

  getApps() {
    this.applications = [];
    for (let i = 0; i < applicationsAndRoles.data.length; i++) {
      this.applications.push(applicationsAndRoles.data[i]['name']);
    }
  }

  getRoles(appName: string) {
    let roles: string[] = [];
    let selectedApp: any = applicationsAndRoles.data.filter(
      (item: any) => item['name'] === appName,
    );
    for (let i = 0; i < selectedApp[0]['roles'].length; i++) {
      roles.push(selectedApp[0]['roles'][i]['name']);
    }
    this.rolesShownForSelectedApp = roles;
  }

  getRoleAttributes(appName: string, ...appRoles: any[]) {
    this.selectedAppRoles = appRoles[0];
    this.selectedAppRolesLength = this.selectedAppRoles.length;
    let allAttributesForSelectedRoles: any[] = [];
    this.RelatedAttributesForSeledtedAppAndSelectedRoles = [];
    let allAttributes: any = [];
    let selectedApp: any = applicationsAndRoles.data.filter(
      (item: any) => item['name'] === appName,
    );
    for (let j = 0; j < this.selectedAppRoles.length; j++) {
      var attributesForselectedRoles: any = selectedApp[0]['roles'].filter(
        (item: any) => item['name'] === this.selectedAppRoles[j],
      );
      allAttributesForSelectedRoles.push(
        attributesForselectedRoles[0]['fields']['attributes'],
      );
    }
    for (let i = 0; i < allAttributesForSelectedRoles.length; i++) {
      for (let k = 0; k < allAttributesForSelectedRoles[i].length; k++) {
        allAttributes.push(allAttributesForSelectedRoles[i][k]);
      }
    }
    this.RelatedAttributesForSeledtedAppAndSelectedRoles = allAttributes;
  }

  showBasicAttributes() {
    this.isAccountClicked = true;

    let newValues = {
      active: this.searchedUserDataArray['active'],
      userName: this.searchedUserDataArray['userName'],
      emails: this.searchedUserDataArray['email'],
      givenName: this.searchedUserDataArray['firstName'],
      familyName: this.searchedUserDataArray['lastName'],
      formattedName: this.searchedUserDataArray['formatted'],
    };
    this.basicAttributes = {};
    this.basicAttributes = newValues;
  }

  onAppSelected(app: string) {
    this.selectedApp = app;
    this.getRoles(this.selectedApp);
  }

  onRoleSelected(role: string) {
    this.saveButtonActive = true;
    this.selectedRoles = role;
    this.getRoleAttributes(this.selectedApp, this.selectedRoles);
  }

  @ViewChild('saveButton') saveButton: ElementRef;

  scrollToBottom() {
    this.saveButton.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onSearchHumanUserClick() {
    this.searchedUserDataArray = {};
    this.errorMessage = '';

    this.getHumanUsersService
      .getUserData(
        this.standardPrefixForhumanUserSearchInput +
          '"' +
          this.formGroup.get('searchTerm')?.value +
          '"',
      )
      .subscribe(
        (response) => {
          this.responseToCommonAttributes(response);
          this.responseToAppRelatedAttributes(response);
          if (response?.resources?.length > 0) this.state = 'found';
          else this.state = 'not-found';
        },
        (error: HttpErrorResponse) => {
          this.state = 'not-found';
          this.errorMessage = error.error.error.detail;
        },
      );
  }

  private responseToCommonAttributes(response: any) {
    const data = response['resources'][0];

    this.formResults.patchValue({
      firstname: data?.['name']?.['givenName'],
      lastname: data?.['name']?.['familyName'],
      status: data?.['active'] ? 'active' : 'inactive',
    });
    this.changeDetector.detectChanges();
  }

  private responseToAppRelatedAttributes(response: any) {
    const data =
      response?.['resources']?.[0]?.[
        'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:User'
      ];

    this.formAppRelatedAttributes.patchValue({
      app: ['AEB Customs Portal - Customer'],
      countryCode: data?.['Tenant'],
      locationCode: data?.['LocationCode'],
      companyName: data?.['Company'],
      enterpriseId: data?.['EnterpriseId'],
      customerId: data?.['CustomerId'],
      contactId: data?.['ContactId'],
      csspCustomerIdentification: data?.['CsspCustomerIdentificationAttribute'],
    });
  }

  private initFormAppRelatedAttributes(): void {
    this.formAppRelatedAttributes = this.fb.group({
      app: ['AEB Customs Portal - Customer'],
      countryCode: [''],
      locationCode: [''],
      companyName: [''],
      enterpriseId: ['active'],
      customerId: [],
      contactId: [],
      csspCustomerIdentification: [],
    });
  }
}
