<form>
  <div class="container">
    <div class="roleslabel">
      <label for="multiselect">Roles:</label>
    </div>
    <div class="form-group">
      <div id="multiselect" class="multiselect">
        <div class="selectContainer">
        <div class="selectBox" (click)="showCheckboxes()">
          <select class="form-select">
            <option>Select a Role</option>
          </select>
          <div class="overSelect" #multiselect></div>
        </div>
      </div>
        <div id="dropDownContainer">
          <label for="selectall" *ngIf="expanded">
            <input id="selectall" style="width: 200px;" type="button" (click)="selectAllToggle()" [value]=" allCheckboxesSelected ? 'Cancel all' : 'Select all' " />
          </label>
          <div id="checkboxes">
            <div *ngFor="let role of dropdownItems">
              <label for="{{role}}" (click)="CheckIfAllAreSelected()">
              <input class="checkbox" type="checkbox" id="{{role}}" (change)="onSelect($event)"/>{{ role }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
