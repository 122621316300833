import {FormGroup} from '@angular/forms';

/**
 * Adds an error to a FormGroup itself.
 *
 * @param form - The FormGroup to which the error should be added.
 * @param errorKey - The key for the error to add (e.g., "formError").
 * @param errorMessage - The value for the error (can be a string, object, boolean, etc.).
 */
export function addErrorToFormGroup(
  form: FormGroup,
  errorKey: string,
  errorMessage: string,
): void {
  const currentErrors = form.errors || {};
  form.setErrors({
    ...currentErrors,
    [errorKey]: errorMessage,
  });

  form.markAsTouched(); // (optional) mark form as touched for UI updates
  form.markAsDirty(); // (optional) ensure the form recognizes changes
}
