import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { AppCoreForm } from '@models/forms/app-core-form.schema';
import { ApiProductForm } from '@models/forms/api-product-form.schema';
import {
  GLSTemplateDirective,
  ToggleSwitchMultipleComponent,
  ToggleSwitchMultipleOptionComponent,
} from '@gls/platform-ui';
import { CommonModule } from '@angular/common';
import { CredentialFormGroup } from '@models/forms/credential-form-group.schema';
import { XbpHorizontalLineComponent } from '@xbp-commons/components/xbp-horizontal-line/xbp-horizontal-line.component';
import { appGetRequestRespTOApiProductsFormArray } from '@shared/forms/form-app-core/form-credential/form-api-products/utils/app-get-request-resp-TO-api-products-form-array';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-form-api-products',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleSwitchMultipleComponent,
    GLSTemplateDirective,
    ToggleSwitchMultipleOptionComponent,
    XbpHorizontalLineComponent,
  ],
  templateUrl: './form-api-products.component.html',
  styleUrl: './form-api-products.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class FormApiProductsComponent implements OnInit {
  @Input({ required: true }) credentialFormGroupIndex: number;

  private destroyRef = inject(DestroyRef);
  private formGroupDirective = inject(FormGroupDirective);
  private appTrackerService = inject(AppTrackerService);

  protected apiProductsFormArray = new FormArray<ApiProductForm>([]);
  protected app$ = this.appTrackerService.asyncApp.successfulData$;

  constructor() {}

  ngOnInit(): void {
    this.credentialParentFormGroup.setControl(
      'apiProducts',
      this.apiProductsFormArray,
    );

    this.app$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((app) => {
      appGetRequestRespTOApiProductsFormArray(
        app,
        this.credentialParentFormGroup,
        this.apiProductsFormArray,
      );
      this.parentFormGroup.resetTracking();
    });
  }

  get parentFormGroup() {
    return this.formGroupDirective.form as AppCoreForm;
  }

  // TODO this could return undefined
  get credentialParentFormGroup(): CredentialFormGroup {
    return this.parentFormGroup.controls.credentials.at(
      this.credentialFormGroupIndex,
    );
  }
}
