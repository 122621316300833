import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { ButtonComponent, TooltipDirective } from '@gls/platform-ui';
import { RelativeTimePipe } from '@shared/pipes/relative-time.pipe';
import { appGetRequestRespToCredentialForm } from './utils/app-get-request-resp-TO-credential-form';
import { AppCoreForm } from '@models/forms/app-core-form.schema';
import { CredentialFormGroup } from '@models/forms/credential-form-group.schema';
import { FormApiProductsComponent } from '@shared/forms/form-app-core/form-credential/form-api-products/form-api-products.component';
import { XbpHorizontalLineComponent } from '@xbp-commons/components/xbp-horizontal-line/xbp-horizontal-line.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { ModalAddApiProductComponent } from '@shared/forms/form-app-core/form-credential/modal-add-api-product/modal-add-api-product.component';
import { ApiProductFormValueSchema } from '@models/forms/api-product-form.schema';

@Component({
  selector: 'app-form-credential',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipDirective,
    RelativeTimePipe,
    FormApiProductsComponent,
    XbpHorizontalLineComponent,
    ButtonComponent,
  ],
  templateUrl: './form-credential.component.html',
  styleUrl: './form-credential.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class FormCredentialComponent implements OnInit {
  private formGroupDirective = inject(FormGroupDirective);
  private appTrackerService = inject(AppTrackerService);
  private modalService = inject(ModalService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  protected credentialsFormArray = new FormArray<CredentialFormGroup>([]);
  protected app$ = this.appTrackerService.asyncApp.successfulData$;

  constructor() {}

  ngOnInit(): void {
    this.parentFormGroup.setControl('credentials', this.credentialsFormArray);
    this.parentFormGroup.resetTracking();

    this.app$.subscribe((app) => {
      appGetRequestRespToCredentialForm(app, this.credentialsFormArray);
      this.parentFormGroup.resetTracking();
      this.changeDetectorRef.detectChanges();
    });
  }

  protected addApiProduct(credentialFormGroup: CredentialFormGroup) {
    const existingApiProducts =
      credentialFormGroup.controls.apiProducts.value.map(
        (i) => i.productName ?? '',
      );
    this.modalService.showComponent(ModalAddApiProductComponent, {
      hasBackdrop: true,
      data: {
        existingApiProducts,
        onSubmit: (data: unknown) => {
          const safeParse = ApiProductFormValueSchema.safeParse(data);
          if (!safeParse.success) return;

          const apiProduct = safeParse.data;
          credentialFormGroup.controls.apiProducts.push(
            new FormGroup({
              productName: new FormControl(apiProduct.productName),
              approvalStatus: new FormControl(apiProduct.approvalStatus),
            }),
          );
          this.parentFormGroup.markAsHasChanged();
          this.changeDetectorRef.detectChanges();
        },
      },
    });
  }

  protected get parentFormGroup() {
    return this.formGroupDirective.form as AppCoreForm;
  }
}
