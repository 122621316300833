<div class="main-container">
  <div class="searchBox">
    <div class="input-group">
      <input type="text" class="form-control shadow-none" placeholder="Search an account..." aria-label="Search"
      [(ngModel)]="humanUserSearchInput" (keyup.enter)="onSearchHumanUserClick()" aria-describedby="basic-addon2">
    </div>
  </div>
  <div class="human-users-table-container" #humanUsersTableContainer>
    <div class="human-users-table">
      <table class="table">
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
          </tr>
          <tr (click)="showBasicAttributes()">
            <td> {{ searchedUserDataArray['userName'] }} </td>
            <td> {{ searchedUserDataArray['firstName'] }} </td>
            <td> {{ searchedUserDataArray['lastName'] }} </td>
          </tr>
      </table>
    </div>
  </div>

  <div class="basic-attributes-container">
    <app-basic-attributes [attributes]="basicAttributes" *ngIf="isAccountClicked"></app-basic-attributes>
  </div>
</div>
<div class="apps-container" *ngIf="isAccountClicked">
  <app-applications [dropdownItems]="applications"  (selectedOption)="onAppSelected($event)"></app-applications>
</div>
<div class="related-attributes-container" *ngIf="isAccountClicked && selectedApp !==''">
  <!-- <app-roles [dropdownItems]="rolesShownForSelectedApp"  (selectedOption)="onRoleSelected($event)"></app-roles> -->
  <app-multi-select-dropdown [dropdownItems]="rolesShownForSelectedApp"  (selectedOption)="onRoleSelected($event) ; scrollToBottom()" (click)="scrollToBottom()"></app-multi-select-dropdown>
  <div class="basic-attributes-title-container" *ngIf="selectedAppRolesLength !== 0 && selectedAppRolesLength !== undefined">
    <h5 class="basic-attributes-title">Related Attributes</h5>
    <hr class="rounded">
  </div>
  <app-related-attributes [attributes]="RelatedAttributesForSeledtedAppAndSelectedRoles"></app-related-attributes>
</div>
<div class="save" #saveButton>
  <button class="btn btn-primary pull-right" *ngIf="saveButtonActive && selectedAppRolesLength !== 0 && selectedAppRolesLength !== undefined">Save</button>
</div>


