import { Component, HostListener, inject, Inject } from '@angular/core';
import {
  AlertComponent,
  ButtonComponent,
  GLSTemplateDirective,
  ModalComponent,
} from '@gls/platform-ui';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MODAL_DATA,
  ModalContainerComponent,
} from '../../services/modal/modal-container/modal-container.component';
import { ErrorHeadingPipe } from '../../pipes/error-heading.pipe';
import { ErrorDetailsPipe } from '../../pipes/error-details.pipe';
import { environment } from '@environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-modal-error',
  standalone: true,
  imports: [
    CommonModule,
    GLSTemplateDirective,
    ModalComponent,
    ButtonComponent,
    AlertComponent,
    ErrorHeadingPipe,
    ErrorDetailsPipe,
  ],
  templateUrl: './modal-error.component.html',
  styleUrl: './modal-error.component.scss',
})
export class ModalErrorComponent {
  private msalService = inject(MsalService);
  error: HttpErrorResponse;

  constructor(
    private _modalContainer: ModalContainerComponent<ModalErrorComponent>,
    @Inject(MODAL_DATA) public data: { error: HttpErrorResponse },
  ) {
    this.error = data.error;
  }

  public close(): void {
    this._modalContainer.destroy();
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  protected logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUri,
    });
  }
}
