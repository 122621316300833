import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'dropdown-app',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() dropdownItems:any = [];
  @Input() labelName: string;
  @Output() selectedOption: any = new EventEmitter<string>();

  onSelect(event: Event) {
    this.selectedOption.emit((event.target as HTMLTextAreaElement).value);
  }
}
