import { InjectionToken, Provider } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('Window');

export const WINDOW_PROVIDER: Provider = {
  provide: WINDOW,
  useValue: window,
};

declare global {
  interface Window {
    URL: {
      createObjectURL: (blob: any) => string;
    };
  }
}
