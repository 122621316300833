import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HumanUsersTableComponent } from './human-users/human-users-table/human-users-table.component';
import { BasicAttributesComponent } from './human-users/basic-attributes/basic-attributes.component';
import { RelatedAttributesComponent } from './human-users/related-attributes/related-attributes.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { TableComponent } from './components/table/table.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { ApplicationsComponent } from './applications/applications.component';
import { RolesComponent } from './roles/roles.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalInterceptor } from '@azure/msal-angular';
import { MsalGuard } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AzureAdDemoService } from './services/azure-ad-demo.service';
import { HomeComponent } from './home/home.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './header/header.component';
import { authConfig } from './auth-config';
import { environment } from './environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    HumanUsersTableComponent,
    BasicAttributesComponent,
    RelatedAttributesComponent,
    DropdownComponent,
    TableComponent,
    SwitchButtonComponent,
    ApplicationsComponent,
    RolesComponent,
    MultiSelectDropdownComponent,
    HomeComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    MsalModule.forRoot(new PublicClientApplication(
      {
        auth: {
          clientId: authConfig.clientId,
          redirectUri: environment.redirectUri,
          authority: authConfig.authority
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        }
      }
    ),
    {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: authConfig.scopes
      }
    },
    {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map(
        [
          ['https://graph.microsoft.com/v1.0/me', authConfig.scopes]
        ]
      )
    }
    )
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }, MsalGuard, AzureAdDemoService],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
