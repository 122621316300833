import { z } from 'zod';
import { ExtensionDefinitionSchema } from '../extension-definition.schema';

export const ExtensionsDefinitionGetRequestRespSchema = z.array(
  ExtensionDefinitionSchema,
);

export type ExtensionsDefinitionGetRequestResp = z.infer<
  typeof ExtensionsDefinitionGetRequestRespSchema
>;
