import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { z } from 'zod';

@Pipe({
  name: 'errorHeading',
  standalone: true,
})
export class ErrorHeadingPipe implements PipeTransform {
  /**
   * Transforms the given error into a readable heading string.
   * It customizes HTTP errors and handles Zod validation errors.
   * Special cases for status text or status codes can be easily extended in the future.
   *
   * @param value - The error object to transform.
   * @returns Human-readable string for error, or the original value for unrecognized cases.
   */
  transform(value: unknown): unknown {
    if (value instanceof HttpErrorResponse) {
      return `${this.handleSpecialStatusTextCases(value.statusText)} • ${this.handleSpecialStatusCodeCases(value.status)}`;
    }

    if (value instanceof z.ZodError) {
      return 'Data Validation Error';
    }

    return value;
  }

  /**
   * Transforms or maps special HTTP status texts to custom values.
   * Add new cases here as needed to account for other status text transformations.
   *
   * @param statusText - The HTTP status text.
   * @returns The transformed or original status text.
   */

  private handleSpecialStatusTextCases(statusText: string): string {
    if (statusText === 'Unknown Error') return 'Unauthorized';
    return statusText;
  }

  /**
   * Transforms or maps special HTTP status codes to custom values.
   * Add new cases here as needed to account for other status code transformations.
   *
   * @param status - The HTTP status code.
   * @returns The transformed or original status code.
   */

  private handleSpecialStatusCodeCases(status: number): number {
    if (status === 0) return 401;
    return status;
  }
}
