import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { FormAppCoreComponent } from '@shared/forms/form-app-core/form-app-core.component';
import { AppTrackerService } from '@services/trackers/app-tracker.service';
import { AsyncPipe } from '@angular/common';
import { CardExtensionComponent } from '@shared/forms/card-extension/card-extension.component';
import { ExtensionData } from '@models/business/extensionDataSchema';
import { AppService } from '@services/api/app.service';
import { extractExtensionsFromObject } from '@shared/utils-form/extract-extensions-from-object';
import { AsyncDataV2 } from '@xbp-commons/async-data/async-data-v2.class';
import { withAsyncDataV2 } from '@xbp-commons/async-data/rxjs-operators/with-async-data-v2.operator';
import { XbpForm } from '@xbp-commons/forms/xbp-form';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonComponent, ToastService } from '@gls/platform-ui';
import { isNullOrUndefined } from '@xbp-commons/types/is-null-or-underfined';
import { AppCoreForm } from '@models/forms/app-core-form.schema';
import { CredentialFormGroup } from '@models/forms/credential-form-group.schema';
import { formAppTOAppPutRequestBody } from './utils/form-app-TO-app-put-request-body';
import { filter, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiProductForm } from '@models/forms/api-product-form.schema';
import { isAnEmptyArray } from '@shared/xbp-commons';
import { APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID } from '@models/business/scim-schemas/scim-schema-id';
import { ActionTrackerService } from '@services/trackers/action-tracker.service';
import { withAsyncAction } from '@shared/rxjs-operators/with-async-action';

@Component({
  selector: 'app-form-app',
  standalone: true,
  imports: [
    FormAppCoreComponent,
    AsyncPipe,
    CardExtensionComponent,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  templateUrl: './form-app.component.html',
  styleUrl: './form-app.component.scss',
})
export class FormAppComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private appService = inject(AppService);
  private appTrackerService = inject(AppTrackerService);
  protected actionTrackerService = inject(ActionTrackerService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private toastService = inject(ToastService);

  protected asyncApp = this.appTrackerService.asyncApp;
  protected asyncExtensions = new AsyncDataV2<Map<string, ExtensionData>>();
  protected APP_EXTENSIONS_DEFINITIONS = AppService.APP_EXTENSIONS_DEFINITIONS;

  private readonly CUSTOMS_CONSIGNMENTS_V3 = 'customs-consignments-v3';

  form: AppCoreForm = XbpForm.from(
    new FormGroup({
      id: new FormControl<string>({ value: '', disabled: true }),
      displayName: new FormControl<string>(''),
      credentials: new FormArray<CredentialFormGroup>([]),
      schemas: new FormControl<Array<string>>([], { nonNullable: true }),
      // extensionName: extensionValue
    }),
  );

  ngOnInit(): void {
    this.asyncApp.onChanges$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          ([state]) =>
            state === 'success-with-data' || state === 'success-without-data',
        ),
        withAsyncDataV2(this.asyncExtensions, ([state, data]) =>
          extractExtensionsFromObject(data),
        ),
      )
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.trackFormChanges();
  }

  protected updateApp(): void {
    const app = this.asyncApp.data;
    if (isNullOrUndefined(app)) return;

    const appId = app.id;
    const data = formAppTOAppPutRequestBody(this.form, app);

    this.appService
      .updateApp(appId, data)
      .pipe(
        switchMap(() => this.appService.getApp(appId)),
        withAsyncAction(this.actionTrackerService.action, 'updating'),
        withAsyncDataV2(this.asyncApp, (data) => data),
      )
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.toastService.show({
          message: 'Technical Account updated successfully',
          type: 'success',
        });
      });
  }

  private trackFormChanges() {
    this.form.onChanged$.subscribe(() => {
      if (
        !this.hasApiProductWithNameInAsyncApp(this.CUSTOMS_CONSIGNMENTS_V3) &&
        this.hasApiProductWithName(this.CUSTOMS_CONSIGNMENTS_V3)
      ) {
        this.addGroupAEBCustomsExtensionFormGroup();
      } else if (
        !this.hasApiProductWithNameInAsyncApp(this.CUSTOMS_CONSIGNMENTS_V3) &&
        !this.hasApiProductWithName(this.CUSTOMS_CONSIGNMENTS_V3)
      ) {
        this.removeGroupAEBCustomsExtensionFormGroup();
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  private hasApiProductWithNameInAsyncApp(productName: string) {
    const credentials = this.asyncApp.data?.credentials ?? [];
    if (isAnEmptyArray(credentials)) return false;

    return credentials.some((credential) =>
      credential.apiProducts.some(
        (apiProduct) => apiProduct.productName === productName,
      ),
    );
  }

  private hasApiProductWithName(productName: string): boolean {
    // Get the credentials from the FormArray
    const credentialsFormArray = this.form.controls.credentials;

    if (!credentialsFormArray || credentialsFormArray.length === 0) {
      return false; // No credentials present
    }

    // Iterate through each credential to find the specified apiProduct name
    return credentialsFormArray.controls.some(
      (credentialFormGroup: CredentialFormGroup) => {
        const apiProductsFormArray = credentialFormGroup.controls.apiProducts;

        if (
          !apiProductsFormArray ||
          !(apiProductsFormArray instanceof FormArray)
        ) {
          return false; // No apiProducts present or is not a FormArray
        }

        return apiProductsFormArray.controls.some(
          (apiProductFormGroup: ApiProductForm) =>
            apiProductFormGroup.controls.productName.value === productName,
        );
      },
    );
  }

  private addGroupAEBCustomsExtensionFormGroup(): void {
    const schemasFormControlValue = this.form.controls.schemas.value;
    if (
      !schemasFormControlValue.includes(
        APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
      )
    ) {
      this.form.controls.schemas.setValue([
        ...schemasFormControlValue,
        APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
      ]);
    }

    let extensionsData = this.asyncExtensions.data$.value;
    if (isNullOrUndefined(extensionsData))
      extensionsData = new Map<string, ExtensionData>();

    if (extensionsData.has(APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID)) {
      extensionsData.set(
        APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
        extensionsData.get(APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID)!,
      );
      return;
    } else {
      extensionsData.set(APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID, {});
    }

    this.asyncExtensions.data$.next(extensionsData);
    this.changeDetectorRef.detectChanges();
    this.form.resetTracking();
  }

  private removeGroupAEBCustomsExtensionFormGroup(): void {
    let extensionsData = this.asyncExtensions.data$.value;
    if (isNullOrUndefined(extensionsData)) return;

    extensionsData.delete(APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID);
  }
}
