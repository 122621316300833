import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionDefinitionName',
  standalone: true,
})
export class ExtensionDefinitionNamePipe implements PipeTransform {
  transform(value: string): string {
    const snakeText =
      value.split(':')[6] ?? 'Invalid Extension Definition Name';
    return this.snakeToTitleCase(snakeText).replace('Aeb', 'AEB');
  }

  private snakeToTitleCase(snakeText: string): string {
    return snakeText
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
