import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CredentialFormGroup,
  CredentialFormGroupValueSchema,
} from '@models/forms/credential-form-group.schema';
import { ApiProductForm } from '@models/forms/api-product-form.schema';

export const appGetRequestRespToCredentialForm = (
  app: AppGetRequestResp,
  credentialsFormArray: FormArray<CredentialFormGroup>,
) => {
  credentialsFormArray.clear();

  app.credentials.forEach((credential) => {
    const credentialControl: CredentialFormGroup = new FormGroup({
      clientId: new FormControl(credential.clientId),
      apiProducts: new FormArray<ApiProductForm>([]),
      issuedAt: new FormControl(credential.issuedAt),
      expireAt: new FormControl(credential.expireAt),
    });

    const safeParsed = CredentialFormGroupValueSchema.safeParse(
      credentialControl.value,
    );
    if (safeParsed.success) {
      credentialsFormArray.push(credentialControl);
    } else {
      throw safeParsed.error;
    }
  });
};

const translate = (approvalStatusPassSentence: string) => {
  if (approvalStatusPassSentence === 'approved') return 'approve';
  if (approvalStatusPassSentence === 'revoked') return 'revoke';
  return 'pending';
};
