<div class="session-meta-container">
  <div class="heading">
    <div class="text-subheading">Meta</div>
    <xbp-horizontal-line />
  </div>
  @if (!!(app$ | async)) {
    <div class="information text-body">
      <div class="row-one">
        <div
          class="item-created"
          [glsTooltip]="(app$ | async)!.meta.created | date: 'full'"
          [placement]="'top-start'"
        >
          • Created {{ (app$ | async)!.meta.created | relativeTime }}
        </div>
        <div
          class="item-last-modified"
          [glsTooltip]="(app$ | async)!.meta.lastModified | date: 'full'"
          [placement]="'top-start'"
        >
          • Last Modified {{ (app$ | async)!.meta.lastModified | relativeTime }}
        </div>
        <div class="item-created-by">
          • Created by
          <span>{{ (app$ | async)!.meta.createdBy }}</span>
        </div>
      </div>
    </div>
  } @else {
    <div>No meta information to show</div>
  }
</div>
