import { z } from 'zod';
import { UserTypeSchema } from '../others/user-type';
import { AddressSchema } from '../others/address';
import { EmailSchema } from '../others/email';
import { NameSchema } from '../others/name';
import { PhoneNumberSchema } from '../others/phone-number';

export const CoreUserSchema = z.object({
  active: z.boolean(),
  userName: z.string().email(),
  userType: UserTypeSchema.optional(),
  addresses: z.array(AddressSchema),
  emails: z.array(EmailSchema),
  name: NameSchema,
  phoneNumbers: z.array(PhoneNumberSchema),
  preferredLanguage: z.string().optional(), // (e.g., en-US)
  password: z.string(),
});
