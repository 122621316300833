import { Component, OnInit } from '@angular/core';
import { AzureAdDemoService } from '../services/azure-ad-demo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  isUserLoggedIn: boolean = false;
  constructor(private azureAdDemoService: AzureAdDemoService,
              private router: Router) {}
  ngOnInit(): void {
    this.azureAdDemoService.isUserLoggedIn.subscribe(
      response => {
        this.isUserLoggedIn = response;
      }
    )
  }

}
