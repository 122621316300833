import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppLayoutComponent as GLSAppLayoutComponent,
  AvatarComponent,
  BreadcrumbItemComponent,
  BreadcrumbsComponent,
  DropdownComponent,
  DropdownItemComponent,
  DropdownItemLabelComponent,
  DropdownItemSeparatorComponent,
  IconButtonComponent,
  IconComponent,
  NavigationComponent,
  NavigationContentComponent,
  NavigationFooterComponent,
  NavigationHeaderComponent,
  NavigationMenuItemComponent,
  PageHeaderComponent,
  PopperDirective,
  PreventPopperCloseDirective,
  SearchInputComponent,
} from '@gls/platform-ui';
import { RouterLinkActive } from '@angular/router';
import { PanelLeftComponent } from './components/panel-left/panel-left.component';
import { PanelTopComponent } from './components/panel-top/panel-top.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    NavigationComponent,
    NavigationHeaderComponent,
    GLSAppLayoutComponent,
    SearchInputComponent,
    NavigationContentComponent,
    NavigationMenuItemComponent,
    IconComponent,
    NavigationFooterComponent,
    AvatarComponent,
    IconButtonComponent,
    DropdownComponent,
    DropdownItemLabelComponent,
    DropdownItemComponent,
    PageHeaderComponent,
    PopperDirective,
    PreventPopperCloseDirective,
    DropdownItemSeparatorComponent,
    BreadcrumbsComponent,
    BreadcrumbItemComponent,
    RouterLinkActive,
    PanelLeftComponent,
    PanelTopComponent,
  ],
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {}
