import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountsSearchPage } from './pages/internal/human-accounts/accounts-search/accounts-search.page';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './pages/login/login.component';
import { authGuard } from './guards/auth.guard';
import { ErrorHandlingRoutingModule } from '@gls/platform-ui';

const routes: Routes = [
  {
    path: 'accounts',
    canActivate: [authGuard],
    title: 'XB+ User Management v2',
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
      },
      {
        path: 'search',
        loadComponent: () =>
          import(
            './pages/internal/human-accounts/accounts-search/accounts-search.page'
          ).then((m) => m.AccountsSearchPage),
      },
      {
        path: 'create-human-account',
        loadComponent: () =>
          import(
            './pages/internal/human-accounts/human-account-create/human-account-create.page'
          ).then((m) => m.HumanAccountCreatePage),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: 'gls-account',
        component: AccountsSearchPage,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: '',
    redirectTo: '/accounts',
    pathMatch: 'full',
  },
  {
    path: 'error',
    loadChildren: () => ErrorHandlingRoutingModule,
  },
  {
    path: '**',
    redirectTo: '/error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
