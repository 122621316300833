import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-basic-attributes',
  templateUrl: './basic-attributes.component.html',
  styleUrls: ['./basic-attributes.component.scss']
})
export class BasicAttributesComponent {
  @Input() attributes: any;
}
