<gls-page-header glsLayoutHeaderContent>
  <div header-actions-mobile>
    <gls-icon-button
      icon="icon-navigation-menu-horizontal"
      [raised]="true"
      [glsPopper]="{
        targetComponent: groupSubItem,
        mode: 'click',
        placement: 'right',
        strategy: 'fixed'
      }"
    ></gls-icon-button>
    <gls-dropdown #groupSubItem>
      <gls-dropdown-item-label [label]="'Actions'"></gls-dropdown-item-label>
      <gls-dropdown-item
        label="Primary Action"
        glsPreventPopperClose
      ></gls-dropdown-item>
      <gls-dropdown-item
        label="Secondary Action"
        glsPreventPopperClose
      ></gls-dropdown-item>
      <gls-dropdown-item-separator></gls-dropdown-item-separator>
      <gls-dropdown-item label="Print"></gls-dropdown-item>
    </gls-dropdown>
  </div>

  <div breadcrumbs>
    <gls-breadcrumbs>
      <gls-breadcrumb-item
        *ngFor="let urlSegment of urlSegments"
        label="{{ urlSegment.label }}"
        [url]="urlSegment.url"
      ></gls-breadcrumb-item>
    </gls-breadcrumbs>
  </div>

  <span title-label class="text-page-title">{{ urlSegments.at(-1)?.label }}</span>
  <div class="header-filters-and-tabs" header-content></div>
</gls-page-header>
