import { z } from 'zod';

export const AttributeDefinitionSchema = z.object({
  name: z.string(),
  type: z.union([z.literal('String'), z.literal('Boolean')]),
  description: z.string(),
  extensionAppId: z.string(),
  required: z.boolean(),
  displayName: z.string(),
  validationRegex: z.union([z.literal('none'), z.string()]),
  min: z.union([z.literal('none'), z.string()]),
  max: z.union([z.literal('none'), z.string()]),
});

export type AttributeDefinition = z.infer<typeof AttributeDefinitionSchema>;
