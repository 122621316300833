@if (extensionDefinition && extensionData) {
  <gls-card [interactive]="false">
    <ng-template glsTemplate="header">
      <div class="card-header">
        <h4 class="card-header-title">
          <span>
            {{ extensionDefinition.schema | extensionDefinitionName }}
          </span>
          <span class="text-caption text-color-subdued">
            ({{ extensionDefinition.schema | extensionDefinitionResource }})
          </span>
        </h4>
      </div>
    </ng-template>
    <ng-template glsTemplate="content">
      <div [formGroupName]="extensionDefinition.schema" class="form-container">
        @for (
          attribute of extensionDefinition.attributes;
          track attribute.name;
          let lastAttribute = $last
        ) {
          @if (isMultiSelectionRegex(attribute.validationRegex)) {
            <xbp-dropdown-searchable
              [formControlName]="attribute.name"
              [placeholder]="'Select ' + attribute.displayName | titlecase"
              [className]="lastAttribute ? 'open-upwards' : ''"
            >
              <span label>
                {{ attribute.displayName | titlecase }}
              </span>
              @for (
                option of extractMultiSelectionOptions(
                  attribute.validationRegex
                );
                track option
              ) {
                <xbp-option [value]="option">
                  {{ getCountryLabelByCode(option) }}
                </xbp-option>
              }
            </xbp-dropdown-searchable>
          } @else {
            <gls-text-input
              [formControlName]="attribute.name"
              autocomplete="off"
            >
              <ng-template glsTemplate="labelSlot">
                {{ attribute.displayName | titlecase }}</ng-template
              >
            </gls-text-input>
          }
        }
      </div>
    </ng-template>
  </gls-card>
} @else {
  <div>No extension definition has been provided</div>
}
