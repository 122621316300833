import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { User } from '@models/business/user/user.schema';
import { validateWithSchema } from '@shared/rxjs-operators/validate-with-schema';
import {
  UserGetRequestResp,
  UserGetRequestRespSchema,
} from '@models/business/user/requests/user-get-request-resp.schema';
import { UserPostRequestRespSchema } from '@models/business/user/requests/user-post-request-resp.schema';
import { UserPutRequestRespSchema } from '@models/business/user/requests/user-put-request-reps.schema';
import { ExtensionsDefinitionGetRequestRespSchema } from '@models/business/extension-definition/requests/extensions-definition-get-request.schema';
import { ExtensionDefinition } from '@models/business/extension-definition/extension-definition.schema';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  public static USER_EXTENSIONS_DEFINITIONS = new ReplaySubject<
    Array<ExtensionDefinition>
  >(1);

  createUser(payload: User) {
    const url = `${this.BASE_URL}/usermanagement/external/scim/v2/Users`;
    return this.http
      .post(url, payload)
      .pipe(validateWithSchema(UserPostRequestRespSchema));
  }

  getUser(email: string): Observable<UserGetRequestResp> {
    const url = `${this.BASE_URL}/usermanagement/external/scim/v2/Users?filter=userName eq "${email}"`;
    return this.http
      .get(url)
      .pipe(validateWithSchema(UserGetRequestRespSchema));
  }

  updateUser(id: string, payload: any) {
    const url = `${this.BASE_URL}/usermanagement/external/scim/v2/Users/${id}`;
    return this.http
      .put(url, payload)
      .pipe(validateWithSchema(UserPutRequestRespSchema));
  }

  getUserExtensionsDefinitions() {
    return this.http
      .get('/assets/mock-data/extensions-definitions-user.json')
      .pipe(
        validateWithSchema(ExtensionsDefinitionGetRequestRespSchema),
        tap((data) => UserService.USER_EXTENSIONS_DEFINITIONS.next(data)),
      );
  }
}
