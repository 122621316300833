import { CoreAppSchema } from '../scim-schemas/scim-schema__core_app';
import { z } from 'zod';
import { validateSCIMSchema } from '../refines/validateSCIMSchema';

export const AppSchema = CoreAppSchema.extend({
  schemas: z.array(z.string()),
})
  .passthrough()
  .superRefine(validateSCIMSchema);

export type App = z.infer<typeof AppSchema>;
