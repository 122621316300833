import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { BehaviorSubject, filter, map, pairwise, startWith } from 'rxjs';
import { deepEqual } from './functions/deep-equal';

// Utility type to infer the raw values of the controls
type RawValue<TControl extends Record<string, AbstractControl>> =
  TControl extends { [key: string]: AbstractControl<any, any> }
    ? {
        [K in keyof TControl]: TControl[K] extends AbstractControl<any, infer V>
          ? V
          : any;
      }
    : never;

export class TrackedForm<
  TControl extends { [key: string]: AbstractControl<any, any> },
> extends FormGroup<TControl> {
  private _initRawValues: RawValue<TControl>;
  private _prevRawValues: RawValue<TControl>;
  private _currRawValues$: BehaviorSubject<RawValue<TControl>>;
  private _hasChanged$ = new BehaviorSubject(false);

  constructor(
    controls: TControl,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);

    // Explicitly cast to match RawValue<TControl>
    this._initRawValues = this.getRawValue() as RawValue<TControl>;
    this._prevRawValues = this.getRawValue() as RawValue<TControl>;
    this._currRawValues$ = new BehaviorSubject(
      this.getRawValue() as RawValue<TControl>,
    );

    this.trackValueChanges();
  }

  public static from<
    TControl extends { [key: string]: AbstractControl<any, any> },
  >(formGroup: FormGroup<TControl>): TrackedForm<TControl> {
    const controls = formGroup.controls as TControl;

    const trackedForm = new TrackedForm<TControl>(
      controls,
      formGroup.validator,
      formGroup.asyncValidator,
    );

    trackedForm._initRawValues = formGroup.getRawValue() as RawValue<TControl>;
    trackedForm._prevRawValues = formGroup.getRawValue() as RawValue<TControl>;
    trackedForm._currRawValues$ = new BehaviorSubject(
      formGroup.getRawValue() as RawValue<TControl>,
    );

    return trackedForm;
  }

  public get initRawValues(): RawValue<TControl> {
    return this._initRawValues;
  }

  public get prevRawValues(): RawValue<TControl> {
    return this._prevRawValues;
  }

  public get currRawValues(): RawValue<TControl> {
    return this._currRawValues$.value;
  }

  public get hasChanged(): boolean {
    return this._hasChanged$.value;
  }

  public get hasChanged$() {
    return this._hasChanged$.asObservable();
  }

  public get onChanged$() {
    return this._hasChanged$.pipe(
      startWith(false),
      pairwise(),
      filter(([prev, curr]) => !prev && curr),
      map(([prev, curr]) => curr),
    );
  }

  public setValuesAndResetInitial(values: RawValue<TControl>): void {
    this._initRawValues = values;
    this._prevRawValues = values;
    this._currRawValues$.next(values);
    this.setValue(values as any); // Type mismatch requires a cast
  }

  public resetToInitialValues(): void {
    this.setValue(this._initRawValues as any); // Type mismatch requires a cast
  }

  patchFormValues(form: FormGroup, values: RawValue<TControl>): void {
    form.patchValue(values);
  }

  private trackValueChanges(): void {
    this.valueChanges
      .pipe(
        startWith(this.initRawValues),
        map(() => this.getRawValue() as RawValue<TControl>),
        pairwise(),
      )
      .subscribe(([prev, curr]) => {
        this._prevRawValues = prev;
        this._currRawValues$.next(curr);

        this._hasChanged$.next(
          !deepEqual(this._initRawValues, this._currRawValues$.value),
        );
      });
  }
}
