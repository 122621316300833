import { z } from 'zod';

export const CORE_USER_ID = 'urn:ietf:params:scim:schemas:core:2.0:User';
export const USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID =
  'urn:ietf:params:scim:schemas:extension:welcome_email:2.0:User';
export const USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID =
  'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:User';

export const CORE_APP_ID = 'urn:ietf:params:scim:schemas:core:2.0:App';
export const APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID =
  'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:App';

export const SCIMSchemaId = z.union([
  z.enum([
    CORE_USER_ID,
    USER_EXTENSION_WELCOME_EMAIL_SCHEMA_ID,
    USER_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
    CORE_APP_ID,
    APP_EXTENSION_GROUP_AEB_CUSTOMS_SCHEMA_ID,
  ]),
  z.string(),
]);

export type SCIMSchemaType = z.infer<typeof SCIMSchemaId>;
