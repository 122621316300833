import { z } from 'zod';
import { schemaToFormGroup } from '@xbp-commons/schema-to-form-group/schema-to-form-group';
import { FormGroup } from '@angular/forms';
import { XbpForm } from '@xbp-commons/forms/xbp-form';

export type InferXbpFormGroup<T extends z.ZodSchema> = XbpForm<
  ExtractControls<T>
>;

type ExtractControls<T extends z.ZodSchema> =
  ReturnType<typeof schemaToFormGroup<T>> extends FormGroup<infer Controls>
    ? Controls
    : never;
