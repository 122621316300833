import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HumanUsersTableComponent } from './human-users/human-users-table/human-users-table.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { UserManagementPage } from './pages/internal/generic-search/user-management.page';
import { LoginComponent } from './pages/login/login.component';
import { authGuard } from './guards/auth.guard';
import { msalGuardExtendedGuard } from './guards/msal-guard-extended.guard';

const routes: Routes = [
  {
    path: 'human-accounts',
    component: HumanUsersTableComponent,
    canActivate: [authGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: 'gls-account',
        component: HumanUsersTableComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: '',
    redirectTo: '/human-accounts',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/human-accounts',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
