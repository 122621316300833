import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HumanUsersTableComponent } from './human-users/human-users-table/human-users-table.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'human-accounts',
    component: HumanUsersTableComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
