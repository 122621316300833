import {
  BehaviorSubject,
  combineLatestWith,
  distinctUntilChanged,
  map,
  Observable,
  pairwise,
  startWith,
} from 'rxjs';
import { DataState } from '@xbp-commons/async-data/data-state.type';
import { filterSuccessWithData } from '@xbp-commons/async-data/rxjs-operators/filter-success-with-data.operator';

export class AsyncDataV2<T> {
  state$ = new BehaviorSubject<DataState>('not-initialized');
  prevState$ = new BehaviorSubject<DataState>('not-initialized');
  data$ = new BehaviorSubject<T | null>(null);

  constructor() {
    this.state$
      .pipe(
        startWith(<DataState>'not-initialized'),
        pairwise(),
        map(([prevState]) => prevState),
      )
      .subscribe((prevState) => this.prevState$.next(prevState));
  }

  onChanges$: Observable<[DataState, T | null]> = this.state$
    .pipe(startWith(<DataState>'not-initialized'), pairwise())
    .pipe(combineLatestWith(this.data$.pipe(startWith(null), pairwise())))
    .pipe(
      distinctUntilChanged(
        ([prevState, prevData], [currState, currData]) =>
          prevState[1] === currState[1],
      ),
      map(([[, currState], [, currData]]) => {
        return [currState, currData];
      }),
    );

  successfulData$ = this.onChanges$.pipe(filterSuccessWithData());

  get state(): DataState {
    return this.state$.value;
  }

  get data(): T | null {
    return this.data$.value;
  }
}
