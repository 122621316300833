import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AzureAdDemoService } from '../services/azure-ad-demo.service';
import { Router } from '@angular/router';
import { GetHumanUsersService } from '../services/get-human-users.service';
import { authConfig } from '../auth-config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isUserLoggedIn: boolean = false;
  private readonly _destroy = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private msalBroadCastService: MsalBroadcastService,
              private authService: MsalService,
              private azureAdDemoService: AzureAdDemoService,
              private router: Router,
              private getHumanUsersService: GetHumanUsersService) {}

  ngOnInit(): void {
    this.msalBroadCastService.inProgress$.pipe(filter((interctionStatus: InteractionStatus) =>
    interctionStatus == InteractionStatus.None),
    takeUntil(this._destroy))
    .subscribe(() => {
      this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
      this.azureAdDemoService.isUserLoggedIn.next(this.isUserLoggedIn);

      var tokenRequest = {
        account: this.authService.instance.getAllAccounts()[0],
        scopes: authConfig.scopes
      };
      this.authService.instance.acquireTokenSilent(tokenRequest)
        .then(response => {
          if (response.idToken) {
            this.getHumanUsersService.accessToken = response.idToken;
          }
        }).catch(error => {
          if (error.status === 401) {
            alert('Authorization failed. Please try again.');
          } else if (error.status === 403) {
            alert('Authentication is expired. Please login again to continue.');
            this.router.navigate(['/home']);
          } else {
            console.error('An error occurred:', error);
          }
        })
    })

  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  login() {
    if(this.msalGuardConfig.authRequest) {
      this.router.navigate(['/human-accounts']);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({postLogoutRedirectUri: '/home'});
  }
}
