import { AppCoreForm } from '@models/forms/app-core-form.schema';
import { AppGetRequestResp } from '@models/business/app/requests/app-get-request-resp.schema';
import {
  AppPutRequestBody,
  AppPutRequestBodySchema,
} from '@models/business/app/requests/app-put-request-body.schema';
import { isNullOrUndefined } from '@xbp-commons/types/is-null-or-underfined';
import { extractExtensionsFromObject } from '@shared/utils-form/extract-extensions-from-object';

export const formAppTOAppPutRequestBody = (
  appForm: AppCoreForm,
  app: AppGetRequestResp,
): AppPutRequestBody => {
  const extensionsData = extractExtensionsFromObject(appForm.value);

  const data = {
    displayName: appForm.controls.displayName.value ?? '',
    approvalStatus: app.approvalStatus,
    schemas: appForm.controls.schemas.value ?? [],
    credentials: (appForm.value.credentials ?? []).map(
      (credentialValueInForm) => {
        const apiProductsValueInForm = credentialValueInForm.apiProducts ?? [];
        return {
          clientId: credentialValueInForm.clientId ?? '',
          approvalStatus: translate(
            app.credentials.find(
              (c) => c.clientId === credentialValueInForm.clientId,
            )?.approvalStatus ?? '',
          ),
          apiProducts: apiProductsValueInForm.map((apiProductInForm) => {
            return {
              productName: apiProductInForm.productName ?? '',
              approvalStatus: translate(apiProductInForm.approvalStatus),
            };
          }),
        };
      },
    ),
    ...Object.fromEntries(extensionsData),
  };

  const safeParse = AppPutRequestBodySchema.safeParse(data);
  if (safeParse.success) {
    return safeParse.data;
  } else {
    throw safeParse.error;
  }
};

const translate = (
  approvalStatusPassSentence: string | null | undefined,
): 'approve' | 'revoke' | 'pending' => {
  if (isNullOrUndefined(approvalStatusPassSentence)) return 'pending';

  if (
    approvalStatusPassSentence === 'approved' ||
    approvalStatusPassSentence === 'approve'
  )
    return 'approve';
  if (
    approvalStatusPassSentence === 'revoked' ||
    approvalStatusPassSentence === 'revoke'
  )
    return 'revoke';

  return 'pending';
};
