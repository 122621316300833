import { ExtensionData } from '../../models/business/extensionDataSchema';

export const extractExtensionsFromObject = (
  object: any,
): Map<string, ExtensionData> => {
  const map = new Map<string, ExtensionData>();

  if (!object) return map;

  const extensionKeys = Object.keys(object).filter((key) =>
    key.startsWith('urn:ietf:params:scim:schemas:extension:'),
  );

  extensionKeys.forEach((key) => {
    map.set(key, object[key]);
  });

  return map;
};
