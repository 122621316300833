import { z } from 'zod';
import { ApiProductFormValueSchema } from './api-product-form.schema';
import { InferFormGroup } from '@xbp-commons/forms';

export const CredentialFormGroupValueSchema = z.object({
  clientId: z.string(),
  apiProducts: z.array(ApiProductFormValueSchema),
  issuedAt: z.number(),
  expireAt: z.number(),
});
export type CredentialFormGroupValue = z.infer<
  typeof CredentialFormGroupValueSchema
>;
export type CredentialFormGroup = InferFormGroup<
  typeof CredentialFormGroupValueSchema
>;
