export const snakeCaseToTitleCase = (value: string | null): string => {
  if (value) {
    value = value.replace(/-/g, ' '); // replace - with space

    return value
      .replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
      .replace(/([A-Z\xC0-\xDF]+)([A-Z\xC0\xDF][a-z\xE0-\xFF])/g, '$1 $2')
      .replace(/\b./g, (char) => char.toUpperCase());
  } else {
    return value ?? '';
  }
};
