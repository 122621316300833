import { CoreAppSchema } from '../scim-schemas/scim-schema__core_app';
import { RefinementCtx, z, ZodIssueCode } from 'zod';

/**
 * Validates SCIM schema constraints:
 * - Ensures the `schemas` array includes all SCIM extension keys.
 * - Validates SCIM extension fields (`urn:ietf:params:scim:schemas:extension:*`).
 * - Ensures that extra fields that don't belong to the static schema or SCIM dynamic extensions are not present.
 */
export const validateSCIMSchema = (data: any, ctx: RefinementCtx): void => {
  const allowedStaticKeys = new Set([
    ...Object.keys(CoreAppSchema.shape),
    'schemas',
    'meta',
  ]);

  const extensionKeys = Object.keys(data).filter((key) =>
    key.startsWith('urn:ietf:params:scim:schemas:extension:'),
  );

  const extraKeys = Object.keys(data).filter(
    (key) =>
      !allowedStaticKeys.has(key) &&
      !key.startsWith('urn:ietf:params:scim:schemas:extension:'),
  );

  // 1. Validate that the `schemas` array includes all SCIM extension keys
  const missingSchemas = extensionKeys.filter(
    (extKey) => !data.schemas.includes(extKey),
  );
  if (missingSchemas.length > 0) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: ['schemas'],
      message: `The following extensions are missing in the schemas array: ${missingSchemas.join(
        ', ',
      )}`,
    });
  }

  // 2. Validate all SCIM extension keys
  extensionKeys.forEach((key) => {
    const extensionSchema = z.record(z.string(), z.any());
    const parseResult = extensionSchema.safeParse(data[key]);

    if (!parseResult.success) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [key],
        message: `Invalid SCIM extension: ${key}`,
      });
    }
  });

  // 3. Report any unexpected extra fields
  if (extraKeys.length > 0) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: [],
      message: `Unexpected extra fields found: ${extraKeys.join(', ')}`,
    });
  }
};
