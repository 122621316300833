import { Component, Input } from '@angular/core';
import { AlertComponent } from '@gls/platform-ui';
import { NullOrUndefined } from '../../../../../../shared/types/null-or-undefined';

@Component({
  selector: 'app-message-search-something',
  standalone: true,
  imports: [AlertComponent],
  templateUrl: './message-search-something.component.html',
  styleUrl: './message-search-something.component.scss',
})
export class MessageSearchSomethingComponent {
  @Input() searchBy: 'Human Account' | 'Technical Account' | NullOrUndefined =
    'Human Account';
}
