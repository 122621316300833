import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetHumanUsersService {
  public accessToken: string;
  private baseURL = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getUserData(filter: string): Observable<any> {
    const url = `${this.baseURL}/usermanagement/external/scim/v2/Users?filter=${filter}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });

    const options = {
      headers: headers,
      withCredentials: false
    };

    return this.http.get(url, options);
  }
}


