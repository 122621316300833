<div class="related-attributes-container">
  <form>
    <div class="related-attributes-controls-container">
      <div class="form-group" *ngFor="let attribute of attributes">
        <label for="name" title="{{ attribute.description }}"> {{ attribute.displayName }} </label>
        <input type="{{ attribute.type }}" id="{{ attribute.name }}" name="{{ attribute.name }}" value="{{attributes.userName}}">
      </div>
    </div>
  </form>
</div>


