<gls-app-layout>
  <app-panel-left aside-content></app-panel-left>

  <div main-content>
    <app-panel-top glsLayoutHeaderContent></app-panel-top>
    <div class="content-containerr">
      <ng-content></ng-content>
    </div>
  </div>
</gls-app-layout>
