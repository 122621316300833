<gls-card [interactive]="false">
  <ng-template glsTemplate="header">
    <div class="card-header">
      <h4 class="card-header-title">
        <div class="text-subheading">
          <span>Common Attributes</span>
          <span class="text-caption text-color-subdued">
            (Technical Account)
          </span>
        </div>
      </h4>
    </div>
  </ng-template>
  <ng-template glsTemplate="content">
    <div class="form-container">
      <div class="two-columns">
        <gls-text-input formControlName="id" autocomplete="off">
          <ng-template glsTemplate="labelSlot">
            Technical Account ID
          </ng-template>
        </gls-text-input>
        <gls-text-input formControlName="displayName" autocomplete="off">
          <ng-template glsTemplate="labelSlot">
            Technical Account Name
          </ng-template>
        </gls-text-input>
      </div>
      <app-form-credential />
      <app-form-meta />
    </div>
  </ng-template>
</gls-card>
