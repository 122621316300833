import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Validates data against all extension metadata.
 * @param dataAccessor A function to extract the relevant portion of data for validation (default: identity function).
 * @param attributesMetadata$ An observable that provides the metadata to validate against.
 * @returns A pipable RxJS operator function.
 */
export function validateWithAttributesMetadata<T>(
  attributesMetadata$: Observable<any>,
  dataAccessor: (data: T) => any = (data) => data,
) {
  return (source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      // Combine the main data with the metadata observable
      switchMap((data) =>
        attributesMetadata$.pipe(
          map((attributesMetadata) => {
            const targetData = dataAccessor(data); // Extract the portion of data to validate

            // Iterate through all metadata schemas and validate their corresponding extensions
            attributesMetadata.forEach((metadata: any) => {
              const extensionKey = metadata.schema; // SCIM extension schema key
              const extensionAttributes = metadata.attributes;

              if (!(targetData.schemas as Array<any>).includes(extensionKey))
                return;

              // Find the corresponding extension data in the app data
              const extensionData = targetData[extensionKey];

              // If the extension data is missing but required by the schema, throw an error
              if (!extensionData) {
                throw new Error(
                  `Extension data for schema '${extensionKey}' is missing in the provided data.`,
                );
              }

              // Perform attribute validation for each defined attribute
              extensionAttributes.forEach((attribute: any) => {
                if (!attribute.required) return;
                const attrValue = extensionData[attribute.name];

                // Validate `required` attributes
                if (
                  attribute.required &&
                  (attrValue === undefined || attrValue === null)
                ) {
                  throw new Error(
                    `Missing required attribute '${attribute.name}' in extension '${extensionKey}'.`,
                  );
                }

                // Validate `min` length, if applicable
                if (
                  attribute.min !== 'none' &&
                  attrValue?.length < parseInt(attribute.min, 10)
                ) {
                  throw new Error(
                    `Attribute '${attribute.name}' in extension '${extensionKey}' is shorter than the minimum length: ${attribute.min}.`,
                  );
                }

                // Validate `max` length, if applicable
                if (
                  attribute.max !== 'none' &&
                  attrValue?.length > parseInt(attribute.max, 10)
                ) {
                  throw new Error(
                    `Attribute '${attribute.name}' in extension '${extensionKey}' exceeds the maximum length: ${attribute.max}.`,
                  );
                }

                // Validate against the `validationRegex`, if applicable
                if (
                  attribute.validationRegex !== 'none' &&
                  !new RegExp(attribute.validationRegex).test(attrValue)
                ) {
                  throw new Error(
                    `Attribute '${attribute.name}' in extension '${extensionKey}' does not match the pattern: ${attribute.validationRegex}.`,
                  );
                }
              });
            });

            // If no errors are thrown, return the original data (validated)
            return data;
          }),
          // Propagate any errors as proper observable errors
          catchError((error) => {
            return throwError(
              () => new Error(`Validation failed: ${error.message}`),
            );
          }),
        ),
      ),
    );
  };
}
