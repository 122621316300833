<div class="basic-attributes-container">
  <h5 class="basic-attributes-title">Basic Attributes</h5>
  <form>
    <div class="form-group switch-button">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" [checked]="attributes.active">
        <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
      </div>
    </div>
    <div class="form-group">
      <label for="username"> Username </label>
      <input type="text" id="username" name="username" value="{{attributes.userName}}">
    </div>
    <div class="form-group">
      <label for="emails"> Email </label>
      <input type="text" id="emails" name="emails" value="{{attributes.emails}}">
    </div>
    <div class="form-group">
      <label for="first-name"> First Name </label>
      <input type="text" id="first-name" name="first-name" value="{{attributes.givenName}}">
    </div>
    <div class="form-group">
      <label for="family-name"> Family Name </label>
      <input type="text" id="family-name" name="family-name" value="{{attributes.familyName}}">
    </div>
    <div class="form-group">
      <label for="formatted-name"> Formatted Name </label>
      <input type="text" id="formatted-name" name="formatted-name" value="{{attributes.formattedName}}">
    </div>
    <div class="form-group">
      <label for="select-language"> Preferred Language </label>
      <select id="select-language" name="select-language" class="form-select">
        <option *ngFor="let language of attributes.preferredLanguage" value="{{attributes.preferredLanguage[0]}}">{{ language }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="user-type"> User Type </label>
      <select id="user-type" name="user-type" class="form-select">
        <option *ngFor="let type of attributes.userType" value="{{attributes.userType[0]}}">{{ type }}</option>
      </select>
    </div>
  </form>
</div>


