import { Component, inject, OnInit } from '@angular/core';
import { ButtonComponent } from '@gls/platform-ui';
import { Router, RouterLink } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, ReplaySubject } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ButtonComponent, RouterLink],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  private msalService = inject(MsalService);
  private router = inject(Router);
  private eventCallbackId: string | null = null;

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

  ngOnInit() {
    this.initializeAuthenticationCallback();
    this.updateAuthenticationStatus();

    this.isAuthenticated()
      .pipe()
      .subscribe((authenticated) => {
        if (authenticated) this.router.navigate(['/accounts']);
      });
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  private updateAuthenticationStatus() {
    const isAuthenticated =
      this.msalService.instance.getAllAccounts().length > 0;
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  private initializeAuthenticationCallback() {
    this.eventCallbackId = this.msalService.instance.addEventCallback(
      (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_START:
            this.updateAuthenticationStatus();
            break;
          case EventType.LOGIN_FAILURE:
            this.isAuthenticatedSubject.next(false);
            break;
          default:
            break;
        }
      },
    );
  }
}
