import { catchError, defer, map, Observable, throwError } from 'rxjs';
import { isNullOrUndefined } from '@xbp-commons/types';
import { isAnEmptyArray } from '@shared/xbp-commons';
import { AsyncDataV2 } from '@xbp-commons/async-data/async-data-v2.class';

export function withAsyncDataV2<T, U>(
  asyncDataProperty: AsyncDataV2<U>,
  adaptFn: (sourceData: T) => U,
) {
  return (source$: Observable<T>): Observable<T> => {
    return defer(() => {
      const currentState = asyncDataProperty.state$.value;

      if (currentState === 'not-initialized') {
        asyncDataProperty.state$.next('loading-first-time');
      } else if (currentState === 'success-with-data') {
        asyncDataProperty.state$.next('updating');
      } else {
        asyncDataProperty.state$.next('loading');
      }

      return source$.pipe(
        // When data is emitted, use adaptFn to transform and set the success state
        map((data: T) => {
          const adaptedData = adaptFn(data);

          asyncDataProperty.data$.next(
            adaptedData === undefined ? null : adaptedData,
          );

          if (!isNullOrUndefined(adaptedData)) {
            if (isAnEmptyArray(adaptedData)) {
              asyncDataProperty.state$.next('success-without-data');
            } else {
              asyncDataProperty.state$.next('success-with-data');
            }
          } else {
            asyncDataProperty.state$.next('success-without-data');
          }

          return data; // Pass the original data downstream
        }),

        // Handle any error by setting the error state
        catchError((error) => {
          asyncDataProperty.state$.next('error');
          // asyncDataProperty.data$.next(null);
          return throwError(() => error);
        }),
      );
    });
  };
}
