import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ExtensionDefinition } from '@models/business/extension-definition/extension-definition.schema';
import { extensionDefinitionToSchema } from '../../extension-definition-to-schema';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { schemaToFormGroup } from '../../utils-form/schema-to-form/schema-to-form-group';
import {
  CardComponent,
  GLSTemplateDirective,
  TextInputComponent,
} from '@gls/platform-ui';
import { TitleCasePipe } from '@angular/common';
import { ExtensionDefinitionNamePipe } from '../../pipes/extension-definition-name.pipe';
import { ExtensionData } from '@models/business/extensionDataSchema';
import { AppCoreForm } from '@models/forms/app-core-form.schema';
import { isNullOrUndefined } from '@xbp-commons/types/is-null-or-underfined';
import { COUNTRIES } from '@models/business/others/tenant';
import { ExtensionDefinitionResourcePipe } from '@shared/pipes/extension-definition-of-what.pipe';
import { XbpSearchableComponent } from '@xbp-commons/ui/dropdown/dropdown-searchable/dropdown-searchable.component';
import { XbpOptionComponent } from '@xbp-commons/ui/dropdown/option/option.component';

@Component({
  selector: 'app-card-extension',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CardComponent,
    GLSTemplateDirective,
    TitleCasePipe,
    ExtensionDefinitionNamePipe,
    ExtensionDefinitionResourcePipe,
    TextInputComponent,
    XbpSearchableComponent,
    XbpOptionComponent,
  ],
  templateUrl: './card-extension.component.html',
  styleUrl: './card-extension.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CardExtensionComponent implements OnInit, OnChanges {
  private changeDetectorRef = inject(ChangeDetectorRef);
  private formGroupDirective = inject(FormGroupDirective);

  @Input() extensionData: ExtensionData | null = null;
  @Input() extensionDefinition: ExtensionDefinition | null = null;

  private COUNTRIES_MAP = new Map<string, string>(
    COUNTRIES.map((country) => [country.code, country.label]),
  );

  constructor() {}

  ngOnInit(): void {
    if (isNullOrUndefined(this.extensionDefinition)) return;
    if (isNullOrUndefined(this.extensionData)) return;

    const schema = extensionDefinitionToSchema(this.extensionDefinition);

    try {
      // this.extensionData = schema.parse(this.extensionData); <- not need to check right now
      const formGroup = schemaToFormGroup(schema);
      formGroup.reset(this.extensionData);
      this.handleSpecialFormCases(formGroup);

      this.parentFormGroup.setControl(
        this.extensionDefinition.schema,
        formGroup,
      );
      this.changeDetectorRef.detectChanges();
      this.parentFormGroup.resetTracking();
    } catch (error) {
      console.error(
        `Extension data does not match extension definition: ${error}`,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['extensionData'] && this.extensionDefinition) {
      this.ngOnInit();
    }
  }

  get parentFormGroup() {
    return this.formGroupDirective.form as AppCoreForm;
  }

  protected isMultiSelectionRegex(validationRegex: string) {
    const multiSelectionPattern = /^\(([^)]+)\)(\|\(([^)]+)\))*$/;
    return multiSelectionPattern.test(validationRegex);
  }

  protected extractMultiSelectionOptions(validationRegex: string) {
    const groupPattern = /\(([^)]+)\)/g;
    let match;
    const options = [];

    // Extract all matches
    while ((match = groupPattern.exec(validationRegex)) !== null) {
      options.push(match[1]);
    }

    return options;
  }

  protected getCountryLabelByCode(code: string): string {
    const countryLabel = this.COUNTRIES_MAP.get(code);
    if (isNullOrUndefined(countryLabel)) return code;

    return `${code} - ${countryLabel}`;
  }

  private handleSpecialFormCases(formGroup: FormGroup) {
    if (formGroup.controls['tenant']) {
      formGroup.controls['tenant'].disable();
    }
  }
}
