import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  GraphEndpointRequestResponse,
  ProfileAzureAD,
} from '../../models/azure-ad/graph-endpoint.model';
import { Observable, ReplaySubject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WINDOW } from '../providers/window.provider';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AzureAdService {
  private profile$ = new ReplaySubject<ProfileAzureAD>(1);
  private imageUrl$ = new ReplaySubject<SafeUrl>(1);

  readonly GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
  readonly PHOTO_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value';

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    @Inject(WINDOW) private window: Window,
  ) {
    this.fetchProfile();
    this.fetchImage();
  }

  getProfile(): Observable<ProfileAzureAD> {
    return this.profile$;
  }

  getProfileImage(): Observable<SafeUrl> {
    return this.imageUrl$;
  }

  private fetchProfile() {
    this.http
      .get<GraphEndpointRequestResponse>(this.GRAPH_ENDPOINT)
      .subscribe((resp) => this.profile$.next(resp));
  }

  private fetchImage() {
    this.http
      .get(this.PHOTO_ENDPOINT, {
        responseType: 'blob',
        headers: new HttpHeaders({ Accept: 'image/jpeg' }),
      })
      .subscribe((blob) => {
        const imageUrl = this.domSanitizer.bypassSecurityTrustUrl(
          this.window.URL.createObjectURL(blob),
        );

        this.imageUrl$.next(imageUrl);
      });
  }
}
