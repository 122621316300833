import { z } from 'zod';
import { AttributeDefinition } from '../models/business/extension-definition/attribute-definition.schema';
import { ExtensionDefinition } from '../models/business/extension-definition/extension-definition.schema';

const attributeDefinitionToSchema = (
  attributeDefinition: AttributeDefinition,
) => {
  let zodSchema;

  switch (attributeDefinition.type) {
    case 'Boolean':
      zodSchema = z.boolean();
      break;

    case 'String':
    default:
      zodSchema = z.string();

      if (
        attributeDefinition.validationRegex &&
        attributeDefinition.validationRegex !== 'none'
      ) {
        zodSchema = zodSchema.regex(
          new RegExp(attributeDefinition.validationRegex),
        );
      }

      if (attributeDefinition.min && attributeDefinition.min !== 'none') {
        zodSchema = zodSchema.min(Number(attributeDefinition.min));
      }

      if (attributeDefinition.max && attributeDefinition.max !== 'none') {
        zodSchema = zodSchema.max(Number(attributeDefinition.max));
      }
      break;
  }

  if (!attributeDefinition.required) {
    zodSchema = zodSchema.optional();
  }

  return zodSchema;
};

export const extensionsDefinitionsToSchemaMap = (
  extensionsDefinition: Array<ExtensionDefinition>,
) => {
  const schemaMap = new Map<string, z.ZodObject<any>>();

  extensionsDefinition.forEach((extensionDefinition) => {
    const { schema: extensionId, attributes: extensionAttributes } =
      extensionDefinition;
    const zodField: Record<string, z.ZodTypeAny> = {};

    extensionAttributes.forEach((attributeDefinition) => {
      zodField[attributeDefinition.name] =
        attributeDefinitionToSchema(attributeDefinition);
    });

    schemaMap.set(extensionId, z.object(zodField));
  });

  return schemaMap;
};

export const extensionDefinitionToSchema = (
  extensionDefinition: ExtensionDefinition,
) => {
  const { schema: extensionId, attributes: extensionAttributes } =
    extensionDefinition;
  const zodField: Record<string, z.ZodTypeAny> = {};
  extensionAttributes.forEach((attributeDefinition) => {
    zodField[attributeDefinition.name] =
      attributeDefinitionToSchema(attributeDefinition);
  });
  return z.object(zodField);
};
